import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import AskToSubscribeModal from "../components/modals/AskToSubscribeModal";
import { addModal } from "../components/modals/ModalManager";
import WelcomeModal from "../components/modals/WelcomeModal";
import { checkForValidOrgSession, checkForValidSession, checkIfFirstLogin, getCurretOrgFromCookie } from "../logic/util";
import wsEventManager from "../logic/wsEventmanager";
import activeChatReducer from "./activeChat";
import { baseAPI, easyFetch, endpoints } from "./api";
import chatListReducer, { fetchChatList } from "./chatList";
import { currentUserReducer, fetchMyDetails, fetchUserDetails } from "./currentUser";

const reducer = combineReducers({
    user: currentUserReducer,
    activeChat: activeChatReducer,
    chatList: chatListReducer,
    [baseAPI.reducerPath]: baseAPI.reducer,
});



const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(baseAPI.middleware).concat(thunk),
});

export function onAppLoaded() {
    if (checkIfFirstLogin() && window.fbq) {
        // LinkedIn insight tag
        window._linkedin_partner_id = "1482812";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
        (function (l) {
            if (!l) {
                window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                window.lintrk.q = []
            }
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript"; b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
        })(window.lintrk);
    }

    if (checkForValidSession()) {
        wsEventManager.init(store.dispatch, getCurretOrgFromCookie())

        if (checkForValidOrgSession()) {
            store.dispatch(fetchChatList())
            store.dispatch(fetchMyDetails()).then(resp => {
                const [respSiteDetails, respUserDetails] = resp

                if (!respSiteDetails.currentPackage) {
                    const trialEnd = new Date(respSiteDetails.trialPeriodEnd);
                    const timeRemainingInMs = trialEnd.getTime() - new Date().getTime();

                    if (timeRemainingInMs <= 0) {
                        // This is okei as logout will reload page
                        function trialOverPopupCheck() {
                            const now = new Date();
                            const daysInMs = 6 * 60 * 60 * 1000;
                            let lastAcceptTS = localStorage.getItem("ask-sub-modal-last-timestamp")
                            let showModal = false

                            if (!lastAcceptTS)
                                showModal = true

                            if (lastAcceptTS) {
                                try {
                                    let lastAcceptDate = new Date(JSON.parse(lastAcceptTS))
                                    const timeDiffInMs2 = now.getTime() - lastAcceptDate.getTime();
                                    showModal = (timeDiffInMs2 >= daysInMs)
                                } catch (error) { }
                            }

                            if (showModal)
                                addModal(<AskToSubscribeModal />, {
                                    locked: true,
                                    data: {
                                        isAdmin: respUserDetails?.currentSiteRole === "OWNER" || respUserDetails?.currentSiteRole === "ADMIN",
                                    }
                                })
                        }

                        trialOverPopupCheck();
                        window.addEventListener('visibilitychange', function () {
                            if (document.visibilityState === 'visible') {
                                trialOverPopupCheck();
                            }
                        });
                    }

                }
            })


            if (checkIfFirstLogin(true) === "has_teams") {
                addModal(<WelcomeModal />, {})
            }

            addFacebookScript(store.dispatch)
        } else {
            store.dispatch(fetchUserDetails())
        }
    }
}


export default store;

function addFacebookScript(dispatch) {
    easyFetch(dispatch(endpoints.adminConfig.initiate())).then(r => {
        var script = document.createElement('script');
        script.crossorigin = "anonymous";
        script.src = "https://connect.facebook.net/en_US/sdk.js"

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: r.fbClientID,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v21.0'
            });
        };

        document.head.appendChild(script);
    }).catch(e => { console.log(e) })
}