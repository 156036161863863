import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Trans, useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import illustration from '../../resources/illustration-2.png';
import { useDeleteSitePopupMutation, useGetSitePopupsQuery, useUpdateSitePopupMutation } from '../../state/api';
import { addErrorToast, addInfoToast } from '../base/ToastManager';
import ConfirmModal from '../modals/ConfirmModal';
import { addModal } from '../modals/ModalManager';
import DefaultLayout from './DefaultLayout';
import './DefaultPage.scss';
import './PopupList.scss';

export default function PopupList(params) {
    const { t } = useTranslation("popup")
    const [deleteSitePopup] = useDeleteSitePopupMutation();
    const { data: popupList, isSuccess: loaded } = useGetSitePopupsQuery()
    const [updateSitePopup] = useUpdateSitePopupMutation()

    const togglePopupActive = (id, isActive) => {
        const body = new FormData();
        body.append("popupData", JSON.stringify({ id, isActive }))

        updateSitePopup(body).unwrap().then(() => {
            addInfoToast(t("Popup updated"))
        }).catch((e) => {
            addErrorToast(t("Something went wrong", { ns: 'common' }))
        })
    }

    const deletePopup = (id) => {
        addModal(<ConfirmModal />, {
            data: {
                title: t("Delete popup ?"),
                body: t("Are you sure you want to remove this popup?"),
                confirmText: t("Delete", { ns: "common" }),
            },
            onConfirm: () => {
                deleteSitePopup({ id }).unwrap().then(() => {
                    addInfoToast(t("Popup deleted"))
                }).catch(() => {
                    addErrorToast(t("Something went wrong", { ns: 'common' }))
                })
            }
        })
    }

    if (!loaded) {
        return <DefaultLayout noContentPad match={params.match}></DefaultLayout>
    }

    if (!popupList || popupList.length === 0) {
        return <PopupListWelcome match={params.match} />
    }

    return (
        <DefaultLayout noContentPad match={params.match}>
            <div className="popup-list-page">
                <div className="content-card title-row">
                    <span className="analytics-title ">
                        {t("Boosters")}
                    </span>
                    <Link to="/popups/new" className="ml-auto" >
                        <Button>
                            {t("Add new")}
                        </Button>
                    </Link>

                </div>
                <div className="content-card popup-list-body">
                    <div className='w-100'>
                        <table className='popup-list'>
                            <thead>
                                <tr>
                                    <th> {t("Booster name")}</th>
                                    {/*<th className='shrink2'>Trigger detected %</th>
                                    <th className='shrink2'>Impressions</th>*/}
                                    <th className='shrink'> {t("Active")}</th>
                                    <th className='shrink'> {t("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {popupList?.map(p => <PopupRow key={p.id} popup={p} togglePopupActive={togglePopupActive} deletePopup={deletePopup} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        </DefaultLayout >
    )
}

function PopupListWelcome(params) {
    const { t } = useTranslation("popup")

    return (
        <DefaultLayout noContentPad match={params.match}>
            <div className="popup-list-page-welcome">
                <div className="welcome-popups">
                    <div className="d-flex flex-column">
                        <div className='title'>
                            <Trans t={t}
                                defaults="Try <secondary>Sales Booster BETA</secondary> to make <br/>more customers finish checkout.<br/><sub>It takes few minutes to set it up and grow revenue.</sub>"
                                components={{
                                    secondary: <span className='secondary'></span>,
                                    sub: <div className='sub-title'></div>
                                }}
                            />
                        </div>
                        <div className='feature-list'>
                            <Trans t={t}
                                shouldUnescape={true}
                                defaults="<block>• Detect customer's exit intent</block><block>• Provide discount code</block><block>• Make more sales</block>"
                                components={{ block: <div></div> }}
                            />
                        </div>
                        <div className='sub-text'>
                            <Trans t={t} defaults="It's free for 30 days, no obligations.<br/>If it helps to lower abandoned carts, you can continue using it<br/> for 29 eur per month" />
                        </div>
                        <Link to="/popups/new" className="mx-auto new-booster-cta" >
                            {t("Create your Sales Booster now")}
                        </Link>
                    </div>
                    <img className='illustration' src={illustration} alt="illustration" />
                </div>
            </div>
        </DefaultLayout >
    )
}
function PopupRow({ popup, togglePopupActive, deletePopup }) {

    return (
        <tr>
            <td >{popup?.name || "Popup"}</td>
            <td className='shrink text-center'>
                <Form.Check style={{}} type="switch" id={"popup-active-" + popup.id} label="" checked={popup.isActive} onChange={e => { togglePopupActive(popup?.id, !popup.isActive) }} />
            </td>
            <td className='shrink text-center'>
                <Link style={{ color: "#abb5be" }} to={{ pathname: "/popups/edit", state: { popup: popup } }}>
                    <i className="fad fa-edit mr-3"></i>
                </Link>
                <button type='button' style={{ color: "#abb5be", border: 0, margin: 0, background: "none" }} onClick={() => { deletePopup(popup.id) }}>
                    <i className="fas fa-trash"></i>
                </button>
            </td>
        </tr>
    )
}