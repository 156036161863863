import LockedModal from "../components/modals/LockedModal";
import { addModal } from "../components/modals/ModalManager";

export function _request(method, link, data, options) {
    let extra = {}
    if (!options) options = {}
    let headers = {}

    if (!options.contentTypeEmpty)
        headers["Content-Type"] = "application/json; charset=utf-8";

    if (false && options.selectedSiteID)
        headers["tw-ASI"] = options.selectedSiteID

    if (options.signal)
        extra['signal'] = options.signal

    var body = (method !== "GET" && data && !options.rawData) ? JSON.stringify(data) : (options.rawData ? data : null);
    return fetch(link, { method, body, headers, ...extra })
        .then(res => {
            if (res.status === 401) {
                // No auth
                window.location.href = "/sign-in";
            } else if (res.status === 413) {
                throw { statusCode: "FILE_TO_LARGE" }
            } else if (res.status === 503) {
                // Maintenance. Reload page so nginx shows maintenance page (query param to force reload)
                addModal(<LockedModal />, {
                    locked: true,
                    data: {
                        title: "System offline",
                        body: "We'll be back shortly",
                        modalIcon: <i className="fas fa-tools"></i>,
                    },
                })
                throw { statusCode: "MAINTENANCE" }
            } else {
                return res
            }

            throw res
        })
        .then(res => res.json()).then(response => {
            if (response.status === "OK") {
                return response.data
            } else {
                throw response
            }
        })
}


export function asQueryString(params) {
    if (!params) return "";
    let entries = Object.entries(params)
    let urlParams = new URLSearchParams();

    entries.forEach(entrie => {
        if (Array.isArray(entrie[1])) {
            entrie[1].forEach(v => {
                if (v)
                    urlParams.append(entrie[0], v)
            })
        } else {
            if (entrie[1])
                urlParams.append(entrie[0], entrie[1])
        }
    })

    return urlParams.toString();
}

export function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
        if (Array.isArray(object[key])) {
            object[key].forEach((v, i) => {
                formData.append(key, v)
            })
        }
        else formData.append(key, object[key])

    })
    return formData;
}