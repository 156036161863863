import { createApi } from '@reduxjs/toolkit/query/react';
import { _request, asQueryString, getFormData } from '../logic/api';

function customBaseQuery({ method, url, data, body, options }, { signal, dispatch, getState }, extraOptions) {
    if (!options) options = {}

    /*
    const { initialized, isAuthenticated, selectedSiteID } = getState().auth
    if (initialized && isAuthenticated && selectedSiteID)
        options.selectedSiteID = selectedSiteID
    */

    options.signal = signal

    return _request(method, url, body, options).then(data => ({ data: data || null })).catch((error) => ({ error }))
}

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded 
 */
export const baseAPI = createApi({
    reducerPath: 'coreApi',
    refetchOnMountOrArgChange: true,
    baseQuery: customBaseQuery,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    tagTypes: ['Assistant', 'AssistantDocuments', 'SettingsEmail', 'AssistantQnA', 'AssistantConv', 'SitePopups'],
    endpoints: (build) => ({
        // /////////////////// //
        // Authentication APIs //
        // /////////////////// //
        login: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/sign-in', body: data }),
        }),
        adminConfig: build.query({
            query: () => ({ method: "GET", url: '/api/app-config' }),
        }),
        appLogin: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/app/sign-in', body: data }),
        }),
        signup: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/sign-up', body: data }),
        }),
        forgot: build.mutation({
            query: (email) => ({ method: "POST", url: '/api/forgot', body: { email } }),
        }),
        recover: build.mutation({
            query: ({ password, password_again, code }) => ({ method: "POST", url: '/api/recover', body: { password, password_again, code } }),
        }),
        verify: build.mutation({
            query: (token) => ({ method: "POST", url: '/api/verify?token=' + token }),
        }),
        removeDevice: build.mutation({
            query: (deviceID) => ({ method: "POST", url: '/api/me/remove-device', body: { deviceID } }),
        }),

        // //////////// //
        // User Profile //
        // //////////// //
        getMyDetails: build.query({
            query: () => ({ method: "GET", url: '/api/me' }),
        }),
        saveMySettings: build.mutation({
            query: (settings) => ({ method: "POST", url: '/api/me/settings', body: settings }),
        }),
        saveMyPrefLang: build.mutation({
            query: (prefLang) => ({ method: "POST", url: '/api/me/preferred-language', body: { prefLang } }),
        }),
        getAllTranslations: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/me/tr', body: data }),
        }),
        getManyTranslations: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/me/tr-many', body: data }),
        }),

        // /////////////// //
        // Organization    //
        // /////////////// //
        newOrg: build.mutation({
            query: (org) => ({ method: "POST", url: '/api/new-org', body: org }),
        }),

        // //////////// //
        // Site & Users //
        // //////////// //
        getSiteDetails: build.query({
            query: () => ({ method: "GET", url: '/api/site' }),
        }),
        updateSiteDetails: build.mutation({
            query: (details) => ({ method: "POST", url: '/api/site/details', body: details }),
        }),
        switchSite: build.mutation({
            query: (siteID) => ({ method: "GET", url: '/api/me/switch?no_redirect=1&site=' + siteID }),
        }),
        getSiteAnalytics: build.query({
            query: (filter) => ({ method: "GET", url: '/api/site/analytics?' + asQueryString(filter) }),
        }),
        saveSiteSettings: build.mutation({
            query: (settings) => ({ method: "POST", url: '/api/site/settings', body: settings }),
        }),
        saveSiteSettingsFeatures: build.mutation({
            query: (features) => ({ method: "POST", url: '/api/site/settings/features', body: features }),
        }),
        setSiteBreak: build.mutation({
            query: (breakUntil) => ({ method: "POST", url: '/api/site/break', body: { breakUntil } }),
        }),
        sendDeveloperGuide: build.mutation({
            query: ({ email, text }) => ({ method: "POST", url: '/api/site/send-to-developer', body: { email, text } }),
        }),
        saveSiteUser: build.mutation({
            query: (user) => ({ method: "POST", url: '/api/site/users', body: user }),
        }),
        removeSiteUser: build.mutation({
            query: (userID) => ({ method: "DELETE", url: '/api/site/users/' + userID }),
        }),
        leaveSite: build.mutation({
            query: () => ({ method: "DELETE", url: '/api/site/users/leave' }),
        }),

        // ////////////// //
        // Messaging APIs //
        // ////////////// //
        fetchChats: build.query({
            query: (filter) => ({ method: "GET", url: '/api/chats?' + asQueryString(filter) }),
        }),
        sendMessage: build.mutation({
            query: (message) => ({ method: "POST", url: '/api/message', body: message, options: { rawData: true, contentTypeEmpty: true } }),
        }),
        editMessage: build.mutation({
            query: (message) => ({ method: "POST", url: '/api/message/edit', body: message }),
        }),
        sendMessageNote: build.mutation({
            query: (message) => ({ method: "POST", url: '/api/message/note', body: message }),
        }),
        sendMessageCustom: build.mutation({
            query: (message) => ({ method: "POST", url: '/api/message/custom', body: message }),
        }),
        sendContactNote: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/message/note', body: data }),
        }),

        // ///////////// //
        // Contact APIs  //
        // ///////////// //
        addContactTag: build.mutation({
            query: (tag) => ({ method: "POST", url: '/api/contact/tag', body: tag }),
        }),
        deleteContactTag: build.mutation({
            query: (tag) => ({ method: "DELETE", url: '/api/contact/tag', body: tag }),
        }),
        deleteContact: build.mutation({
            query: (contactID) => ({ method: "DELETE", url: '/api/contact', body: { contactID } }),
        }),

        // ///////////////////////// //
        // Quick Reply & Popup APIs  //
        // ///////////////////////// //
        quickReplies: build.query({
            query: () => ({ method: "GET", url: '/api/site/quick-replies' }),
        }),
        newQuickReply: build.mutation({
            query: (quickReply) => ({ method: "POST", url: '/api/site/quick-replies', body: quickReply }),
        }),
        updateQuickReply: build.mutation({
            query: (quickReply) => ({ method: "POST", url: '/api/site/quick-replies/update', body: quickReply }),
        }),
        deleteQuickReply: build.mutation({
            query: (quickReply) => ({ method: "DELETE", url: '/api/site/quick-replies', body: quickReply }),
        }),
        getSitePopups: build.query({
            query: () => ({ method: "GET", url: '/api/site/popups' }),
            providesTags: (_result, _err, id) => [{ type: 'SitePopups' }],
        }),
        saveSitePopup: build.mutation({
            query: (popup) => ({ method: "POST", url: '/api/site/popups', body: popup, options: { rawData: true, contentTypeEmpty: true } }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SitePopups' }],
        }),
        updateSitePopup: build.mutation({
            query: (popup) => ({ method: "POST", url: '/api/site/popups/update', body: popup, options: { rawData: true, contentTypeEmpty: true } }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SitePopups' }],
        }),
        deleteSitePopup: build.mutation({
            query: (popup) => ({ method: "DELETE", url: '/api/site/popups', body: popup }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SitePopups' }],
        }),

        // ////////////////// //
        // Social Media APIs  //
        // ////////////////// //
        addPageFacebook: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/facebook/pages', body: data }),
        }),
        unsubscribeFacebook: build.mutation({
            query: (pageID) => ({ method: "DELETE", url: '/api/site/facebook', body: { pageID } }),
        }),

        // ///////////////// //
        // Assistant APIs   //
        // ///////////////// //
        getAssistant: build.query({
            query: () => ({ method: "GET", url: '/api/site/assistant/settings' }),
            providesTags: (_result, _err, id) => [{ type: 'Assistant', site_id: _result.site_id }],
        }),
        updateAssistant: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/assistant/settings', body: data }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Assistant' }],
        }),
        updateAssistantSecondary: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/assistant/settings-secondary', body: data }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Assistant' }],
        }),
        getAssistantDocuments: build.query({
            query: () => ({ method: "GET", url: '/api/site/assistant/document/list' }),
            providesTags: (_result, _err, id) => [{ type: 'AssistantDocuments', site_id: _result.site_id }],
        }),
        updateAssistantDocument: build.mutation({
            query: ({ id, data }) => ({ method: "POST", url: '/api/site/assistant/document/' + id, body: data }),
            invalidatesTags: (result, error, { id }) => [{ type: 'AssistantDocuments' }],
        }),
        deleteAssistantDocuments: build.mutation({
            query: (id) => ({ method: "DELETE", url: '/api/site/assistant/document/' + id }),
            invalidatesTags: (result, error, { id }) => [{ type: 'AssistantDocuments' }],
        }),
        uploadAssistantDocuments: build.mutation({
            query: (selectedFiles) => {
                const body = new FormData();

                if (selectedFiles && selectedFiles.length > 0) {
                    for (var i = 0; i < selectedFiles.length; i++) {
                        body.append("files", selectedFiles[i]);
                    }
                }

                return { method: "POST", url: "/api/site/assistant/document", body, options: { rawData: true, contentTypeEmpty: true } }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'AssistantDocuments' }],
        }),
        setupAssistant: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/assistant/setup', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'AssistantQnA' }],
        }),
        getAssistantQnA: build.query({
            query: ({ id }) => ({ method: "GET", url: '/api/site/assistant/qna/' + id }),
            providesTags: (_result, _err, id) => [{ type: 'AssistantQnA' }],
        }),
        getAssistantQnAs: build.query({
            query: () => ({ method: "GET", url: '/api/site/assistant/qna/list' }),
            providesTags: (_result, _err, id) => [{ type: 'AssistantQnA' }],
        }),
        createAssistantQnA: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/assistant/qna', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'AssistantQnA' }],
        }),
        updateAssistantQnA: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/assistant/qna/update', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'AssistantQnA' }],
        }),
        deleteAssistantQnA: build.mutation({
            query: (data) => ({ method: "DELETE", url: '/api/site/assistant/qna', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'AssistantQnA' }],
        }),
        importAssistantQnA: build.mutation({
            query: (file) => {
                const body = new FormData();
                body.append("file", file);
                return { method: "POST", url: "/api/site/assistant/qna/import", body, options: { rawData: true, contentTypeEmpty: true } }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'AssistantQnA' }],
        }),
        sendTestAssistantMessage: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/assistant/test-conversation', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'AssistantConv' }],
        }),

        // ///////////// //
        // Email APIs   //
        // ///////////// //
        getEmailSettings: build.query({
            query: () => ({ method: "GET", url: '/api/site/settings/email' }),
            providesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        addEmailDomain: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/settings/email/domain', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        deleteEmailDomain: build.mutation({
            query: (data) => ({ method: "DELETE", url: '/api/site/settings/email/domain', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        getEmailAddresses: build.query({
            query: () => ({ method: "GET", url: '/api/site/settings/email/addresses' }),
            providesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        addEmailAddress: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/settings/email/address', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        deleteEmailAddress: build.mutation({
            query: (data) => ({ method: "DELETE", url: '/api/site/settings/email/address', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        updateEmailAddressSignature: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/settings/email/address/signature', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        sendMessageEmail: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/message/email', body: getFormData(data), options: { rawData: true, contentTypeEmpty: true } }),
        }),
        getEmailMessage: build.query({
            query: (data) => ({ method: "GET", url: '/api/message/email?' + asQueryString(data) }),
        }),

        // ///////////////// //
        // Subscription APIs //
        // ///////////////// //
        subscriptionPrices: build.query({
            query: () => ({ method: "GET", url: '/api/subscriptions/prices' }),
        }),
        startSubscription: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/subscriptions/start', body: data }),
        }),
        checkSubscription: build.mutation({
            query: () => ({ method: "POST", url: '/api/subscriptions/check' }),
        }),

        // //////////////// //
        // Integration APIs //
        // //////////////// //
        integrationsCreatePipedrive: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/integrations/pipedrive', body: data }),
        }),
        integrationsRemovePipedrive: build.mutation({
            query: () => ({ method: "DELETE", url: '/api/integrations/pipedrive' }),
        }),
        integrationsCreatePipedriveLead: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/integrations/pipedrive/lead', body: data }),
        }),

        // ////////////// //
        // Feature APIs  //
        // ////////////// //
        featureRequest: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/request/feature', body: data }),
        }),
        featureTrialRequest: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/request/feature-trial', body: data }),
        }),

        // ////////////// //
        // Feedback APIs  //
        // ////////////// //
        reportTranslation: build.mutation({
            query: (report) => ({ method: "POST", url: '/api/report/messsage', body: report }),
        }),
    }),
})

export const {
    // Authentication hooks
    useLoginMutation,
    useAdminConfigQuery,
    useAppLoginMutation,
    useSignupMutation,
    useForgotMutation,
    useRecoverMutation,
    useVerifyMutation,
    useRemoveDeviceMutation,

    // User Profile hooks
    useGetMyDetailsQuery,
    useSaveMySettingsMutation,
    useSaveMyPrefLangMutation,
    useGetAllTranslationsMutation,
    useGetManyTranslationsMutation,

    // Organization hooks
    useNewOrgMutation,

    // Site & Users hooks
    useGetSiteDetailsQuery,
    useUpdateSiteDetailsMutation,
    useSwitchSiteMutation,
    useGetSiteAnalyticsQuery,
    useSaveSiteSettingsMutation,
    useSaveSiteSettingsFeaturesMutation,
    useSetSiteBreakMutation,
    useSendDeveloperGuideMutation,
    useSaveSiteUserMutation,
    useRemoveSiteUserMutation,
    useLeaveSiteMutation,

    // Messaging hooks
    useFetchChatsQuery,
    useSendMessageMutation,
    useEditMessageMutation,
    useSendMessageNoteMutation,
    useSendMessageCustomMutation,
    useSendContactNoteMutation,

    // Contact hooks
    useAddContactTagMutation,
    useDeleteContactTagMutation,
    useDeleteContactMutation,

    // Quick Reply & Popup hooks
    useQuickRepliesQuery,
    useNewQuickReplyMutation,
    useUpdateQuickReplyMutation,
    useDeleteQuickReplyMutation,
    useGetSitePopupsQuery,
    useSaveSitePopupMutation,
    useUpdateSitePopupMutation,
    useDeleteSitePopupMutation,

    // Social Media hooks
    useAddPageFacebookMutation,
    useUnsubscribeFacebookMutation,

    // Assistant hooks
    useGetAssistantQuery,
    useUpdateAssistantMutation,
    useUpdateAssistantSecondaryMutation,
    useGetAssistantDocumentsQuery,
    useUpdateAssistantDocumentMutation,
    useDeleteAssistantDocumentsMutation,
    useUploadAssistantDocumentsMutation,
    useSetupAssistantMutation,
    useGetAssistantQnAQuery,
    useGetAssistantQnAsQuery,
    useCreateAssistantQnAMutation,
    useUpdateAssistantQnAMutation,
    useDeleteAssistantQnAMutation,
    useImportAssistantQnAMutation,
    useSendTestAssistantMessageMutation,

    // Email hooks
    useGetEmailSettingsQuery,
    useAddEmailDomainMutation,
    useDeleteEmailDomainMutation,
    useGetEmailAddressesQuery,
    useAddEmailAddressMutation,
    useDeleteEmailAddressMutation,
    useUpdateEmailAddressSignatureMutation,
    useSendMessageEmailMutation,
    useGetEmailMessageQuery,

    // Subscription hooks
    useSubscriptionPricesQuery,
    useStartSubscriptionMutation,
    useCheckSubscriptionMutation,

    // Integration hooks
    useIntegrationsCreatePipedriveMutation,
    useIntegrationsRemovePipedriveMutation,
    useIntegrationsCreatePipedriveLeadMutation,

    // Feedback hooks
    useFeatureRequestMutation,
    useFeatureTrialRequestMutation,
    useReportTranslationMutation,

    endpoints,
} = baseAPI


export function easyFetch(requestPromise) {
    return requestPromise.then(({ data, isLoading, isSuccess /*...*/ }) => data).catch(error => error).finally(() => { requestPromise?.unsubscribe?.(); })
}