import cn from 'classnames'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { PopupModal, useCalendlyEventListener } from "react-calendly"
import { Trans, withTranslation } from "react-i18next"

import { useSelector } from 'react-redux'
import { addInfoToast } from '../base/ToastManager'
import './ConfirmModal.scss'
import './Modal.scss'

class CalendlyModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            calendlyOpen: false,
        }
        this.confirmClick = this.confirmClick.bind(this)
        this.cancelClick = this.cancelClick.bind(this)
        this.setCalendlyClose = this.setCalendlyClose.bind(this)
    }

    confirmClick() {
        this.setState({ calendlyOpen: true });
    }

    cancelClick() {
        if (this.props.onCancel) {
            this.props.onCancel()
            this.props.onClose();
        } else {
            this.props.onClose()
        }
    }

    setCalendlyClose() {
        this.setState({ calendlyOpen: false });
        this.props.onCancel()
        this.props.onClose();
    }

    render() {
        let data = this.props.data || {}
        const { t } = this.props;

        return (
            <Modal size='md' className={cn("tw-modal confirm-modal", { 'secondary': this.props.isSecondary })} centered show={this.props.show} onHide={this.props.onClose} animation={false}>
                <h5 className="modal-title my-2 text-center">
                    <Trans t={t} defaults="AI Like Human:<br>Easy path to smart AI-powered automation" />
                </h5>
                <p className="footnote">
                    <Trans t={t} defaults='Customers value quick replies 24/7.<br/>Whether it’s AI or human, as long as they get what they need.' />
                    <br /><br />
                    <Trans t={t} defaults='<b>With Askly AI Assistant you can:</b>' components={{ b: <b /> }} />
                    <ul>
                        <li>{t("Save up to 60% of your team’s time.")}</li>
                        <li>{t("Grow customer satisfaction.")}</li>
                        <li>{t("Reduce customer support costs by up to 75%")}</li>
                    </ul>
                    <Trans t={t} defaults='<b>Pricing:</b>' components={{ b: <b /> }} />
                    <ul>
                        <li>{t("AI Assistant Lite now from 89 eur/month")}</li>
                        <ul>
                            <li style={{ listStyleType: 'none', fontSize: 14, fontStyle: 'italic' }}>{t("up to 10 topics and 1000 responses monthly")}</li>
                        </ul>
                        <li>{t("Advanced AI Assistant: 220-359 eur/month")}</li>
                        <ul>
                            <li style={{ listStyleType: 'none', fontSize: 14, fontStyle: 'italic' }}>{t("up to 100 topics and 5000 responses monthly")}</li>
                        </ul>
                    </ul>
                </p>
                <CalendlyWrap open={this.state.calendlyOpen} close={this.setCalendlyClose} />
                <div className="mt-4 mb-2 mx-3 d-flex">

                    <Button disabled={this.state.isLoading} className={cn({ "mr-auto": !data.hideConfirm })} variant="outline-primary" onClick={this.cancelClick}>
                        {t("Back")}
                    </Button>
                    <Button disabled={this.state.isLoading} className='mr-3' variant="success" href="/settings/site/assistant" >
                        <span className="confirm-text">{data.confirmText ? data.confirmText : t("Try now")}</span>
                    </Button>
                    <Button disabled={this.state.isLoading} variant="primary" onClick={this.confirmClick}>
                        <span className="confirm-text">{data.confirmText ? data.confirmText : t("Book a demo")}</span>
                    </Button>
                </div>
            </Modal>
        );
    }
}

export default withTranslation("modals")(CalendlyModal);

function CalendlyWrap(params) {
    const user = useSelector(state => state.user?.userDetails)
    useCalendlyEventListener({
        onEventScheduled: (e) => addInfoToast("Event scheduled"),
    });


    return <PopupModal
        url="https://calendly.com/askly_me/askly-assistant-demo"
        rootElement={document.getElementById("root")}
        prefill={{
            email: user.email,
            name: user.name,
        }}
        onModalClose={params.close}
        open={params.open}
    />
}
