import { useForm } from '@mantine/form';
import { useHotkeys } from '@mantine/hooks';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { executePromiseMinTime, extractInitials, getLanguage, getLanguages, humanFileSize, isValidURL } from '../../../logic/util';
import illustration from '../../../resources/illustration-10.png';
import noChats from '../../../resources/no-chats.png';
import { useCreateAssistantQnAMutation, useDeleteAssistantDocumentsMutation, useDeleteAssistantQnAMutation, useFeatureTrialRequestMutation, useGetAssistantDocumentsQuery, useGetAssistantQnAQuery, useGetAssistantQnAsQuery, useGetAssistantQuery, useSendTestAssistantMessageMutation, useSetupAssistantMutation, useUpdateAssistantDocumentMutation, useUpdateAssistantMutation, useUpdateAssistantQnAMutation, useUploadAssistantDocumentsMutation } from '../../../state/api';
import { fetchSiteDetails } from '../../../state/currentUser';
import { TestChatPreview } from '../../base/ChatPreview';
import { addErrorToast, addGeneralTopToast, addInfoToast, clearTopToast } from '../../base/ToastManager';
import ChipsInput from '../../base/minor/ChipsInput';
import LoadingOverlay from '../../base/minor/LoadingOverlay';
import SearchDropdown from '../../base/minor/SearchDropdown';
import AssistantHiddenModal from '../../modals/AssistantHiddenModal';
import ConfirmModal from '../../modals/ConfirmModal';
import { addModal } from '../../modals/ModalManager';
import { DefaultSettingsWrap } from './Base';
import { TabInfo } from './ChatWidget';

export default function AsklyAssistant({ hasAssistantFeature, assistantTrialEnded }) {
    const [tab, setTab] = useState("")
    const [activeQna, setActiveQna] = useState(null)
    const user = useSelector(state => state.user)
    const { data: assistantData, refetch: assistantRefetch, isLoading: assistantIsLoading } = useGetAssistantQuery()
    const { data: documents } = useGetAssistantDocumentsQuery()
    const [uploadAssistantDocuments] = useUploadAssistantDocumentsMutation()
    const [deleteDocument] = useDeleteAssistantDocumentsMutation()
    const [updateAssistantDocument] = useUpdateAssistantDocumentMutation()
    const [updateAssistant, { isLoading: isUpdating }] = useUpdateAssistantMutation()
    const { t } = useTranslation("settings_assistant")
    let fileInput = useRef(null);

    useHotkeys([
        ['mod+shift+I', () => {
            addModal(<AssistantHiddenModal />)
        }],
    ]);

    const attachmentsUpdate = (e) => {
        if (fileInput.current.files) {
            addGeneralTopToast(t("Uploading files..."))

            let uploadPromise = uploadAssistantDocuments(fileInput.current.files).unwrap().then(() => {
                addInfoToast(t("Files uploaded"))
            }).catch(() => {
                addErrorToast(t("Failed to upload document"))
            }).finally(() => {
                fileInput.current.value = ''
                fileInput.current.files = null
            })

            executePromiseMinTime(uploadPromise, 1500).finally(() => {
                clearTopToast()
            })
        }
    };

    const updateAssistantWrap = ({ enabled, personality, useFAQ, version }) => {
        updateAssistant({ enabled, personality, useFAQ, version }).unwrap().then(() => {
            addInfoToast(t("Assistant updated"))
        }).catch(() => {
            addErrorToast(t("Failed to update assistant"))
        })
    }

    const toggleActive = (id, active) => {
        updateAssistantDocument({ id, data: { active } }).unwrap().then(() => {
            addInfoToast(t("Document updated"))
        }).catch(() => {
            addErrorToast(t("Failed to update document"))
        })
    }

    const deleteDoc = (docID) => {
        deleteDocument(docID).unwrap().then(() => {
            addInfoToast(t("Document removed"))
        }).catch((e) => {
            addErrorToast(t("Failed to remove document"))
        })
    }

    const [defaultTab, secondaryTabs] = useMemo(() => {
        if (assistantIsLoading)
            return []
        if (assistantData.version === "2")
            return ["answers", [
                { name: <span><i className="fas fa-book mr-2 my-auto"></i>{t("Answers")}</span>, value: "answers" },
                { name: <span><i className="fas fa-sparkles mr-2 my-auto"></i>{t("Test chat")}</span>, value: "chat" },
            ]]

        return ["documents", [{ name: <span><i className="fas fa-book mr-2 my-auto"></i>{t("Documents")}</span>, value: "documents" }]]
    }, [assistantIsLoading, assistantData, t])

    useEffect(() => {
        setTab(defaultTab)
    }, [defaultTab])

    let isLoaded = user?.isLoaded && user?.isSiteLoaded && !assistantIsLoading
    let hasAssistantSetup = assistantData?.id > 0 && isLoaded

    if (!isLoaded)
        return (
            <DefaultSettingsWrap
                subPageTitle={t("AI Assistant", { ns: 'settings_nav' })}
                canSave={false}>
                <div className="chat-settings-wrap w-100 h-100">
                </div>
            </DefaultSettingsWrap >
        )

    // If has no access to feature
    if (!hasAssistantFeature)
        return (
            <DefaultSettingsWrap
                subPageTitle={t("AI Assistant", { ns: 'settings_nav' })}
                canSave={false}>
                <div className="chat-settings-wrap w-100 h-100">
                    <UpgradeToAssistant assistantTrialEnded={assistantTrialEnded} />
                </div>
            </DefaultSettingsWrap >
        )

    // If has no access to feature
    if (!hasAssistantSetup)
        return (
            <DefaultSettingsWrap
                subPageTitle={t("AI Assistant", { ns: 'settings_nav' })}
                canSave={false}>
                <div className="chat-settings-wrap w-100 h-100 position-relative">
                    <NewAssistantForm assistantRefetch={assistantRefetch} />
                </div>
            </DefaultSettingsWrap >
        )

    return (
        <DefaultSettingsWrap
            subPageTitle={t("AI Assistant", { ns: 'settings_nav' })}
            optionClick={(val) => { setTab(val) }}
            canSave={false}
            activeSubOption={tab}
            secondRow={secondaryTabs}
            buttons={(<div className="d-flex align-items-center">
                <AssistantToggle initialData={assistantData} isUpdating={isUpdating} updateAssistant={updateAssistantWrap} />
                {tab === "documents" && <div className='ml-4'>
                    <Button variant="success" className="float-right" onClick={() => { fileInput?.current?.click() }}>{t("Upload")}</Button>
                    <input ref={fileInput} onChange={attachmentsUpdate} type="file" accept=".adk,.pdf,.txt,.doc,.docx,.html,.md" multiple={true} disabled={false} style={{ display: 'none' }} />
                </div>}
                {tab === "answers" && <div className='ml-4'>
                    <Button variant="success" className="float-right" onClick={() => { setActiveQna("new") }}>{t("Add new")}</Button>
                </div>}
            </div>)}>
            <div className="chat-settings-wrap w-100 h-100">
                {tab === "documents" &&
                    <Form>
                        <TabInfo noToggle >
                            <div className='d-flex flex-column'>
                                {t("Add documents to improve the performance of AI Assistant when replying to the customers")}
                            </div>
                        </TabInfo>
                        <Form.Group className="">
                            {!documents?.length && <div style={{ fontSize: 14, color: "#bdc8e0" }}>{t("No documents")}</div>}
                            {documents?.map((d => {
                                return <DocumentRow key={d.id} data={d} delete={() => { deleteDoc(d.id) }} toggle={toggleActive} />
                            }))}
                        </Form.Group>
                    </Form>}
                {tab === "answers" && <AnswersTab activeQna={activeQna} setActiveQna={setActiveQna} />}
                {tab === "chat" && <TestConversation />}
            </div>
        </DefaultSettingsWrap >
    )
}

function AssistantToggle({ isUpdating, initialData, updateAssistant }) {
    const { t } = useTranslation("settings_assistant")

    const updateAssistantForm = (e) => {
        e.preventDefault()
        updateAssistant({ ...initialData, enabled: e.target.checked });
    }

    return <div>
        <Form.Check type="switch" id="pos-switch" checked={initialData?.['enabled'] || false} label={t("Assistant active")} onChange={updateAssistantForm} />
    </div>
}


function DocumentRow(props) {
    const { t } = useTranslation("settings_assistant")

    return (
        <div className="member d-flex align-items-center mb-3">
            <div className="author d-flex align-items-center">
                <div className="avatar d-flex align-items-center justify-content-center">
                    {extractInitials(props.data.name)}
                </div>
                <div>
                    <p className="author-name">{props.data.name}</p>
                    <p className="email">{props.data.size ? humanFileSize(props.data.size) : ""}</p>
                </div>
                <div className="actions ml-auto"></div>
            </div>
            <div className="dropdown add-new-team-member ml-auto d-flex align-items-center">
                {props.data.status !== "" && <Form.Check type="switch" className="mr-3" id={"doc-switch-" + props.data.id} checked={props.data.active || false} label={t(props.data.active ? "Active" : "Inactive")} onChange={() => { props.toggle(props.data.id, !props.data.active) }} />}
                {props.data.status === "" && <div className='mr-3 text-danger'>{t("")}</div>}
                <DropdownButton title={<i className="far fa-ellipsis-v"></i>} menuAlign="right">
                    <Dropdown.Item href={"/api/site/assistant/document/" + props.data.id + "/download"} download target="_blank"  >{t("Download")}</Dropdown.Item>
                    <Dropdown.Item onClick={props.delete}>{t("Remove")}</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    )
}


function QnaRow(props) {
    const { t } = useTranslation("settings_assistant")

    return (
        <div role='button' className={cn("member d-flex align-items-center mb-3 cursor-pointer", { active: props.active })} onClick={props.open}>
            <div className="author d-flex align-items-center pos-relative" style={{ width: "calc(100% - 48px)" }}>
                <div className='w-100'>
                    <p className="author-name">{props.data.title}</p>
                    <p className="email text-truncate">{props.data.answer}</p>
                </div>
                <div className="actions ml-auto"></div>
            </div>
            <div className="dropdown add-new-team-member ml-auto d-flex align-items-center">
                <div className="remove-qna" onClick={props.delete}><i className="fas fa-trash m-auto"></i></div>
            </div>
        </div>
    )
}

function AnswersTab({ activeQna, setActiveQna }) {
    const { t } = useTranslation("settings_assistant")
    const { data, isLoading } = useGetAssistantQnAsQuery()
    const [deleteQna] = useDeleteAssistantQnAMutation()

    const confirmDelete = (qnaID) => {
        addModal(
            <ConfirmModal />,
            {
                data: {
                    title: t("Remove Q&A"),
                    body: t("Are you sure you want to delete this Q&A?"),
                    confirmText: t("Delete", { ns: "common" }),
                    cancelText: t("Back", { ns: "common" }),
                },
                onConfirm: () => {
                    deleteQna({ id: qnaID }).unwrap().then(() => {
                        addInfoToast(t("Q&A removed"))
                    }).catch((e) => {
                        addErrorToast(t("Failed to remove qna"))
                    })
                },
            },
        )
    }


    return (
        <div className='d-flex w-100'>
            <div className='col-6 overflow-auto' style={{ borderRight: "2px solid #eaf1ff" }}>
                <TabInfo noToggle >
                    <div className='d-flex flex-column text-center'>{t("List of your QnA knowledge. Click 'Add new' to add more.")}</div>
                </TabInfo>
                <Form.Group className="">
                    {!isLoading && !data?.length && <div style={{ fontSize: 14, color: "#bdc8e0", textAlign: 'center' }}>
                        <p>{t("No Questions & Answers found")}</p>
                        <Button size='sm' onClick={() => setActiveQna("new")}>{t("Add your first Q&A")}</Button>
                    </div>}
                    {!isLoading && data?.map((d => {
                        return <QnaRow key={d.id} active={activeQna === d.id} data={d} delete={(e) => { e.stopPropagation(); confirmDelete(d.id) }} toggle={() => { }} open={() => { setActiveQna(d.id) }} />
                    }))}
                </Form.Group>
            </div>
            <div className='col-6 overflow-auto'>
                <TabInfo noToggle >
                    <div className='d-flex flex-column text-center'>{t("Specify questions and answer that you want automated")}</div>
                </TabInfo>
                <FormQna selectedID={activeQna} setActiveQna={setActiveQna} createNew={activeQna === "new"} />
            </div>
        </div>
    )
}


function FormQna({ selectedID, createNew, setActiveQna }) {
    const { t } = useTranslation("settings_assistant")
    const [createQna, { isLoading: isCreating }] = useCreateAssistantQnAMutation()
    const [updateQna, { isLoading: isUpdating }] = useUpdateAssistantQnAMutation()
    const { data, isLoading: isQnALoading } = useGetAssistantQnAQuery({ id: selectedID }, { skip: (createNew || !selectedID || selectedID === 0) })
    const form = useForm({
        initialValues: {
            answer: '',
            title: '',
            questions: [{ isNew: true, question: '' }],
        },
    });

    useEffect(() => {
        if (createNew) {
            form.reset()
        }
    }, [createNew])

    useEffect(() => {
        if (!createNew && !isQnALoading && data) {
            form.setValues({
                id: data.id,
                answer: data.answer || '',
                title: data.title || '',
                questions: data.questions?.map(q => ({ ...q })) ?? [{ isNew: true, question: '' }],
            })
        }
    }, [data, isQnALoading, createNew])

    function saveQna(params) {
        const { id, title, answer, questions } = form.values

        // Mark each question as isUpdated if it is not new and form.isDirty(fieldpath)
        questions.forEach((q, i) => {
            if (!q.isNew && !q.deleted && form.isDirty(`questions.${i}.question`)) {
                q.isUpdated = true
            }
        })

        if (createNew) {
            createQna({ title: title, answer, questions: questions }).unwrap().then((r) => {
                setActiveQna(null)
                addInfoToast(t("Q&A created"))
            }).catch(e => {
                addErrorToast(t("Could not save Q&A"))
            })
        } else {
            updateQna({ id, title: title, answer, questions: questions }).unwrap().then(() => {
                addInfoToast(t("Q&A updated"))
            }).catch(e => {
                addErrorToast(t("Could not save Q&A"))
            })
        }
    }

    let extraQuestions = useMemo(() => {
        return form?.values['questions']?.map((q, i) => (
            <QuestionRow index={i}
                label={t("Question example")}
                placeholder={t("Example customer question")}
                key={form.key(`questions.${i}.question`)}
                inputProps={{
                    ...form?.getInputProps(`questions.${i}.question`),
                }}
                onDelete={() => form.setFieldValue(`questions.${i}.deleted`, true)}
                isNew={q?.isNew}
                isDeleted={q.deleted} />
        ))
    }, [form?.values['questions']])

    if (!selectedID && !createNew) {
        return <div className='d-flex w-100'>
            <figure className="mx-auto">
                <img className="w-100" src={noChats} alt="No qna" style={{ maxWidth: 260 }} />
                <figcaption className="text-center" style={{ fontSize: "14px", color: "#5d6c89" }}>
                    <p>{t("No Question & Answer selected")}</p>
                </figcaption>
            </figure>
        </div>
    }

    return <div style={{ minWidth: 450 }}>
        <LoadingOverlay visible={isQnALoading} text={"Loading"} />
        <LoadingOverlay visible={isCreating || isUpdating} text={"Saving"} />
        <Form.Group>
            <Form.Label>{t("Title")}<span className="general-error ml-2">{""}</span></Form.Label>
            <Form.Control type="text" placeholder={t("QnA Title")} maxLength={55} {...form.getInputProps('title')} />
        </Form.Group>
        {extraQuestions}
        {form?.values['questions']?.length < 10 && <Form.Group>
            <Button size='sm' variant='link' onClick={() => form.insertListItem('questions', { isNew: true, question: '' })}>{t("Add question")}</Button>
        </Form.Group>}
        <Form.Group>
            <Form.Label>{t("Answer")}<span className="general-error ml-2">{""}</span></Form.Label>
            <Form.Control rows="4" as="textarea" type="text" placeholder={t("Write the answer here")} row={4} maxLength={1655} {...form.getInputProps('answer')} />
        </Form.Group>
        <Form.Group>
            <Button variant="success" type='button' onClick={saveQna}>{t("Save", { ns: "common" })}</Button>
        </Form.Group>
    </div>
}

function QuestionRow({ label, placeholder, inputProps, isDeleted, onDelete }) {
    const { t } = useTranslation("settings_assistant")

    if (isDeleted) {
        return null
    }

    return < Form.Group >
        <Form.Label className='d-flex'>{label}<span className="general-error ml-2"></span><span role='button' className="general-info ml-auto cursor-pointer" onClick={onDelete}>{t("Remove")}</span></Form.Label>
        <Form.Control as="input" type="text" placeholder={placeholder} {...inputProps} maxLength={164} />
    </Form.Group >
}


function TestConversation(params) {
    const [sendMessage, { isLoading: isSending }] = useSendTestAssistantMessageMutation()
    const { t, i18n } = useTranslation("settings_assistant")

    const siteSettings = {}
    const form = useForm({
        initialValues: {
            language: i18n.language ?? "en",
            input: "",
            isAssigned: false,
            messages: [],
        },
    });

    const reset = () => {
        form.reset()
    }


    const sendMsg = (e) => {
        e.preventDefault()

        let { language, input, messages } = form.values || {}
        let nextMsg = {
            content: input,
            createdAt: new Date(),
        }
        let newMsgs = [...messages, nextMsg]
        form.setValues({
            input: "",
            messages: newMsgs
        })

        sendMessage({ messages: newMsgs, language }).unwrap().then((r) => {
            let { messages, isAssigned } = r
            form.setValues({
                input: "",
                isAssigned,
                messages: messages
            })
        }).catch((e) => {
            addErrorToast(t("Something went wrong"))
        })

    }

    return <div className='container'>
        <div className='row mt-2'>
            <div className="col-6 preview-wrap d-none d-lg-flex justify-content-center">
                <TestChatPreview
                    className="cs-step-1"
                    form={form}
                    isAssistantWriting={isSending}
                    onSend={sendMsg}
                    themeColor={siteSettings.mainColor || "#27304E"}
                    logoURL={(siteSettings.supImgURL ? siteSettings.supImgURL + "?" + (new Date().getTime()) : null)}
                    hideOrgMsg={siteSettings.hideOrgMsg}
                    pii_notification={siteSettings.pii_notification}
                    hasForm={siteSettings.hasForm}
                    hasFAQ={siteSettings.hasFAQ}
                    hasCallUs={siteSettings.hasCallUs}
                    openTimes={siteSettings.schedules}
                    bgPattern={siteSettings.bgPattern}
                />
            </div>
            <div className='col-6'>
                <div>
                    <div className='mb-4 d-flex align-items-center justify-content-between'>
                        <SearchDropdown className=" mb-2" menuWidth={220} label={t("Assistant language")} leftAlign currentSelect={getLanguage((form.values?.['language'] || "en"), "iso")?.eng} allSelection={getLanguages()} onSelect={defLng => { form.setFieldValue("language", getLanguage(defLng, "eng").iso) }} />
                        <Button className='mt-2' variant="success" onClick={reset}>{t("Start again")}</Button>
                    </div>
                    <h1><Trans t={t} defaults={t("Test your<br>AI Assistant")} /></h1>
                    <TabInfo noToggle >
                        <div className='text-center'>
                            <Trans t={t} defaults={t("Write to AI Assistant. Assistant will answer based on the active Q&N's in the <i>'Answers'</i> tab")} /></div>
                    </TabInfo>
                    <figure style={{ maxHeight: 180, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img src={illustration} alt="Illustration" style={{ maxHeight: 160, filter: 'grayscale(0.6)' }} />
                    </figure>
                </div>
            </div>
        </div>
    </div>
}

function WelcomeSetup({ start }) {
    const { t, i18n } = useTranslation("settings_assistant")

    return (
        <div className='d-flex w-100 pt-5'>
            <div className='col-6'>
                <h1><Trans t={t} defaults={t("Setup your<br>AI Assistant")} /></h1>
                <TabInfo noToggle >
                    <div className='text-center'>
                        <Trans t={t} defaults={t("Set up your AI Assistant in minutes—just add info and first topics to automate.")} /></div>
                </TabInfo>
                <div className='d-flex flex-column'>
                    <Button className='mt-2 mx-auto' onClick={start} variant="success">{t("Start setup")}</Button>
                </div>
            </div>
            <div className='col-6 px-4'>
                <figure style={{ maxHeight: 480, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src={illustration} alt="Illustration" style={{ maxHeight: 280, }} />
                </figure>
            </div>
        </div>

    )
}

function UpgradeToAssistant({ assistantTrialEnded }) {
    const { t, i18n } = useTranslation("settings_assistant")
    const [featureTrial, { isLoading }] = useFeatureTrialRequestMutation();
    const history = useHistory()
    const dispatch = useDispatch()

    const requestTrial = () => {
        featureTrial({ feature: 'AsklyAssistant' }).unwrap().then(() => {
            dispatch(fetchSiteDetails())
            addInfoToast(t("Trial started"))
        }).catch((e) => {
            if (e?.statusCode === "FEATURE_TRIAL_ALREADY_STARTED") {
                addErrorToast(t("Trial already started"))
            } else {
                addErrorToast(t("Failed to request trial"))
            }
        })
    }

    return (
        <div className='d-flex w-100 pt-5'>
            <div className='col-6'>
                <h1><Trans t={t} defaults={t("Get started with<br>AI Assistant")} /></h1>
                <TabInfo noToggle >
                    <div className='text-center'>
                        <Trans t={t} defaults={t("To enable your AI Assistant, please upgrade your subscription. AI Assistant is part of the package from 89 eur/month.")} /></div>
                </TabInfo>
                <div className='d-flex flex-row justify-content-center'>
                    <Button className='mt-2 ' onClick={() => { history.push("/settings/subscription") }} variant="success">{t("Upgrade to use AI Assistant")}</Button>
                    {!assistantTrialEnded && <Button className='mt-2 ml-3' onClick={requestTrial} variant="primary">{t("Start 14 day AI Assistant trial")}</Button>}
                    {assistantTrialEnded && <div className='mt-2 ml-3 d-flex align-items-center'   >{t("Trial has ended")}</div>}
                </div>
            </div>
            <div className='col-6 px-4'>
                <figure style={{ maxHeight: 480, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src={illustration} alt="Illustration" style={{ maxHeight: 280, }} />
                </figure>
            </div>
        </div>

    )
}


function NewAssistantForm({ assistantRefetch }) {
    const { t } = useTranslation("settings_assistant")
    const user = useSelector(state => state.user)
    const [step, setStep] = useState(0)
    const [createAssistant, { isLoading }] = useSetupAssistantMutation()
    const form = useForm({
        initialValues: {
            company_name: '',
            domain: '',
            industry_core: [],
            operation_area: [],
            operation_area_details: "",
            industry_grouping_service: [],
            industry_grouping_service_other: "",
            industry_grouping_retail: [],
            industry_grouping_retail_other: "",
            customer_profile: [],
        },
        validateInputOnChange: true,
        validateInputOnBlur: true,
        validate: {
            company_name: (value) =>
                value?.length > 0 ? null : '*Required',
            domain: (value) =>
                (isValidURL(value) && (value?.length > 0)) ? null : '*Valid url is required',
            industry_core: (value) =>
                value?.length > 0 ? null : '*Required',
            industry_grouping_service: (value, { industry_core, industry_grouping_service_other }) =>
                (!industry_core?.some((tmp) => tmp === "Services") || (value?.length > 0 || industry_grouping_service_other?.length > 0)) ? null : '*Required',
            industry_grouping_service_other: (value, { industry_core, industry_grouping_service }) =>
                (!industry_core?.some((tmp) => tmp === "Services") || (value?.length > 0 || industry_grouping_service?.length > 0)) ? null : '*Required',
            industry_grouping_retail: (value, { industry_core, industry_grouping_retail_other }) =>
                (!industry_core?.some((tmp) => tmp === "Retail & E-Commerce") || (value?.length > 0 || industry_grouping_retail_other?.length > 0)) ? null : '*Required',
            industry_grouping_retail_other: (value, { industry_core, industry_grouping_retail }) =>
                (!industry_core?.some((tmp) => tmp === "Retail & E-Commerce") || (value?.length > 0 || industry_grouping_retail?.length > 0)) ? null : '*Required',
            customer_profile: (value) =>
                value?.length > 0 ? null : '*Required',
            operation_area: (value) =>
                value?.length > 0 ? null : '*Required',
        },
    });

    useEffect(() => {
        form.setInitialValues({
            company_name: user?.siteDetails?.name,
            domain: user?.siteDetails?.siteSettingsWrap?.siteDomain,
            industry_core: [],
            operation_area: [],
            operation_area_details: "",
            industry_grouping_service: [],
            industry_grouping_service_other: "",
            industry_grouping_retail: [],
            industry_grouping_retail_other: "",
            customer_profile: [],
        })
        form.reset();
        form.validate();
    }, [user])

    const next = () => {
        if (step === 1) {
            form.validate()
            if (form.errors?.['company_name'] || form.errors?.['domain']) {
                addErrorToast(t("Please fill out the fields"))
                return
            }
        }

        if (step === 2) {
            form.validate()
            if (form.errors?.['company_name'] || form.errors?.['domain'] || form.errors?.['industry_core'] || form.errors?.['customer_profile']) {
                addErrorToast(t("Please fill out the fields"))
                return
            }
        }

        if (step === 3) {
            if (!form.isValid()) {
                addErrorToast(t("Please fill out the fields"))
                return
            }
        }

        if (step === 3) {
            setup()
            return
        }

        setStep(step + 1)
    }

    const setup = () => {
        // todo validate
        createAssistant(form.values).unwrap().then(() => {
            addInfoToast(t("Assistant created"))
            assistantRefetch()
        }).catch((e) => {
            addErrorToast(t("Failed assistant creation"))
        })
    }

    if (step === 0) {
        return <WelcomeSetup start={next} />
    }

    return (
        <div className='assistant-form-wrap d-flex flex-column w-75 mx-auto '>
            <LoadingOverlay visible={isLoading} text={"Generating"} />
            <div className="stepper-wrapper">
                <div className={cn("stepper-item", { completed: step > 1, active: step === 1 })}>
                    <div className="step-counter">1</div>
                </div>
                <div className={cn("stepper-item", { completed: step > 2, active: step === 2 })}>
                    <div className="step-counter">2</div>
                </div>
                <div className={cn("stepper-item", { completed: step > 3, active: step === 3 })}>
                    <div className="step-counter">3</div>
                </div>
            </div>
            {step === 1 && <div>
                <Form.Group>
                    <Form.Label className="mb-0">{t("Organization Name")}
                        <span className={cn("ml-2", { "general-error": form.errors?.['company_name'], "general-success": !form.errors?.['company_name'] })}>{t("*Required", { 'ns': 'common' })}</span>
                    </Form.Label>
                    <div className='chips-description'>{t("Add the name of your organization or brand used the most")}</div>
                    <Form.Control type="text" placeholder={t("Company name")} maxLength={55} {...form.getInputProps('company_name')} />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="mb-0">{t("Domain")}
                        <span className={cn("ml-2", { "general-error": form.errors?.['domain'], "general-success": !form.errors?.['domain'] })}>{t("*Required", { 'ns': 'common' })}</span>
                    </Form.Label>
                    <div className='chips-description'>{t("Your website domain")}</div>
                    <Form.Control type="text" placeholder={t("Your website domain")} maxLength={1565} {...form.getInputProps('domain')} />
                </Form.Group>

            </div>}
            {step === 2 && <div>
                <ChipsInput
                    label={t("Operation area")}
                    className="mb-3"
                    disableInserting={true}
                    allowOther={true}
                    inputOtherKey={form.key('operation_area_details')}
                    inputOtherParams={form.getInputProps('operation_area_details')}
                    error={form.errors?.['operation_area']}
                    description={t("Pick the area of your operations. You can choose both")}
                    addBadge={(val) => { form.setFieldValue('operation_area', [...form.values['operation_area']?.filter((tmp) => tmp !== val), val]) }}
                    removeBadge={(val) => { form.setFieldValue('operation_area', form.values['operation_area']?.filter((tmp) => tmp !== val)) }}
                    badgeSelection={form.values['operation_area']}
                    renderBadge={(v) => t(v)}
                    allBadges={[
                        "Domestic",
                        "International",
                    ]} />
                <ChipsInput
                    label={t("Target audience")}
                    className="mt-3"
                    error={form.errors?.['customer_profile']}
                    description={t("Pick your target customers. One or multiple")}
                    addBadge={(val) => { form.setFieldValue('customer_profile', [...form.values['customer_profile']?.filter((tmp) => tmp !== val), val]) }}
                    removeBadge={(val) => { form.setFieldValue('customer_profile', form.values['customer_profile']?.filter((tmp) => tmp !== val)) }}
                    badgeSelection={form.values['customer_profile']}
                    renderBadge={(v) => t(v)}
                    allBadges={[
                        "Consumers",
                        "Businesses",
                    ]} />
                <ChipsInput
                    label={t("Business sector")}
                    className="mt-3"
                    error={form.errors?.['industry_core']}
                    description={t("Pick your business sector. One or multiple")}
                    addBadge={(val) => { form.setFieldValue('industry_core', [...form.values['industry_core']?.filter((tmp) => tmp !== val), val]) }}
                    removeBadge={(val) => { form.setFieldValue('industry_core', form.values['industry_core']?.filter((tmp) => tmp !== val)) }}
                    badgeSelection={form.values['industry_core']}
                    allowOther={false}
                    renderBadge={(v) => t(v)}
                    allBadges={[
                        "Services",
                        "Retail & E-Commerce",
                    ]} />
            </div>}
            {step === 3 && <div>
                {form.values['industry_core']?.some((tmp) => tmp === "Retail & E-Commerce") &&
                    <ChipsInput
                        className={"mt-3"}
                        label={t("Select retail & e-commerce subcategory")}
                        allowOther={true}
                        inputOtherKey={form.key('industry_grouping_retail_other')}
                        inputOtherParams={form.getInputProps('industry_grouping_retail_other')}
                        error={form.errors?.['industry_grouping_retail'] && form.errors?.['industry_grouping_retail_other']}
                        description={t("Pick appropriate sub-categories for your company.")}
                        addBadge={(val) => { form.setFieldValue('industry_grouping_retail', [...form.values['industry_grouping_retail']?.filter((tmp) => tmp !== val), val]) }}
                        removeBadge={(val) => { form.setFieldValue('industry_grouping_retail', form.values['industry_grouping_retail']?.filter((tmp) => tmp !== val)) }}
                        badgeSelection={form.values['industry_grouping_retail']}
                        renderBadge={(v) => t(v)}
                        allBadges={indusrtyGroups(["Retail & E-Commerce"])}
                    />}
                {form.values['industry_core']?.some((tmp) => tmp === "Services") &&
                    <ChipsInput
                        className={"mt-3"}
                        label={t("Select Services subcategory")}
                        allowOther={true}
                        inputOtherKey={form.key('industry_grouping_service_other')}
                        inputOtherParams={form.getInputProps('industry_grouping_service_other')}
                        error={form.errors?.['industry_grouping_service'] && form.errors?.['industry_grouping_service_other']}
                        description={t("Pick appropriate sub-categories for your company.")}
                        addBadge={(val) => { form.setFieldValue('industry_grouping_service', [...form.values['industry_grouping_service']?.filter((tmp) => tmp !== val), val]) }}
                        removeBadge={(val) => { form.setFieldValue('industry_grouping_service', form.values['industry_grouping_service']?.filter((tmp) => tmp !== val)) }}
                        badgeSelection={form.values['industry_grouping_service']}
                        renderBadge={(v) => t(v)}
                        allBadges={indusrtyGroups(["Services"])}
                    />}
            </div>}
            <div className='d-flex justify-content-center' style={{ width: "100%", gap: 16 }}>
                {step > 1 && <Button variant="outline-secondary" className="mt-4 flex-grow-1" onClick={() => { setStep(step - 1) }}>{t("Back", { 'ns': 'common' })}</Button>}
                <Button variant="success" className="mt-4 flex-grow-1" style={{ maxWidth: '50%' }} onClick={next}>{t("Next", { 'ns': 'common' })}</Button>
            </div>


        </div >
    )
}

function indusrtyGroups(industries) {
    let list = []

    if (industries.includes("Wholesale Trade"))
        list.push(...[
            "Wholesale Trade-durable Goods",
            "Wholesale Trade-non-durable Goods",
        ])

    if (industries.includes("Retail & E-Commerce"))
        list.push(...[
            "Electronics and Tech",
            "Fashion, Apparel, and Accessories",
            "Furniture and Home Essentials",
            "Toys, Hobbies, and Crafts",
            "Beverages and Food",
            "Automotive and Parts",
            "Office Supplies and Equipment",
            "Pet Supplies",
            "Beauty and Personal Care",
        ])

    if (industries.includes("Services"))
        list.push(...[
            "Tourism and Accomodation",
            "Personal and Lifestyle",
            "Business and Corporate",
            "Automotive",
            "Entertainment",
            "Recreation and Leisure",
            "Healthcare",
            "Legal and Financial",
            "Education and Training",
        ])

    return list
}