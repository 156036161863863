import cn from 'classnames'
import React from 'react'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { withTranslation } from "react-i18next"
import { executePromiseMinTime } from '../../logic/util'
import './ConfirmModal.scss'
import './Modal.scss'

class ConfirmModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isLoading: false, inputPhrase: "" }
        this.confirmClick = this.confirmClick.bind(this)
        this.cancelClick = this.cancelClick.bind(this)
    }

    confirmClick() {
        if (this.props.data.includeLoading) {
            this.setState({ isLoading: true });
            executePromiseMinTime(Promise.resolve(this.props.onConfirm()), 500).then(() => {
                this.setState({ isLoading: false });
                this.props.onClose();
            });
        } else {
            if (this.props.onConfirm) this.props.onConfirm();
            this.props.onClose();
        }
    }

    cancelClick() {
        if (this.props.onCancel) {
            this.props.onCancel()
            this.props.onClose();
        } else {
            this.props.onClose()
        }
    }

    render() {
        let data = this.props.data || {}
        const { t } = this.props;
        let confirmInputMissmatch = data?.confirmInput?.phrase && data?.confirmInput?.phrase?.toLowerCase() !== this.state?.inputPhrase?.toLowerCase()
        let confirmDisabled = confirmInputMissmatch || this.state.isLoading
        return (
            <Modal size={this.props.size ? this.props.size : 'md'} className={cn("tw-modal confirm-modal", { 'secondary': this.props.isSecondary })} centered show={this.props.show} onHide={this.props.onClose} animation={false}>
                {data.modalIcon &&
                    <div className="modal-icon">
                        {data.modalIcon}
                    </div>}
                <h5 className="modal-title my-2">{data.title}</h5>
                <p className="footnote">{data.body}</p>

                {data?.confirmInput && <Form.Group className='mt-2 mx-4'>
                    <Form.Label className='mb-1'>{data?.confirmInput?.label}</Form.Label>
                    <Form.Control value={this.state?.inputPhrase ?? ""} onChange={(e) => { this.setState({ isLoading: false, inputPhrase: e?.target.value }) }} />
                </Form.Group>}
                <div className="mt-4 mb-2 mx-3 d-flex">
                    <div className="ml-auto">

                    </div>
                    {!data.hideClose &&
                        <Button disabled={this.state.isLoading} className={cn({ "mr-3": !data.hideConfirm })} variant="outline-primary" onClick={this.cancelClick}>
                            {data.cancelText ? data.cancelText : t("Cancel")}
                        </Button>}
                    {!data.hideConfirm &&
                        <Button className={cn({ "confirm-input-missmatch": confirmInputMissmatch })} disabled={confirmDisabled} variant="primary" onClick={this.confirmClick}>
                            <span className="confirm-text">{data.confirmText ? data.confirmText : t("OK")}</span>
                            <div className="loader">{t("Loading...")}</div>
                        </Button>}
                </div>
            </Modal>
        );
    }
}

export default withTranslation("common")(ConfirmModal);