import i18next from 'i18next';
import { addErrorToast, addInfoToast } from "../components/base/ToastManager";
import ConfirmModal from "../components/modals/ConfirmModal";
import { addModal } from "../components/modals/ModalManager";
import pageTitleNotification from '../logic/tabNotification';
import wsEventManager from '../logic/wsEventmanager';
import { fetchChatList } from "./chatList";

const initialState = {
    loading: false,
    activeContactID: 0,
    isVisitorWriting: false,
    contact: {},
    messages: [],
    scrollToMessageID: null,
    unreadMessages: 0,
    error: null,
}

export const updateChatLanguages = (changeData) => (dispatch, getState) => {

    let isCurrent = (getState().activeChat.activeContactID === changeData.contactID)
    if (isCurrent)
        wsEventManager.sendEventWithCB({ eventType: "CHAT:SET_LANGS", languages: changeData }).then(r => {
            dispatch({ type: "CHAT:SET_LANGS", payload: changeData })
        }).catch(e => {
            addErrorToast(i18next.t("Could not change language"))
        })
};

export const otherSiteMessage = (siteID, contactID) => ({ type: "EXTERNAL:SITE_MESSAGE", payload: { siteID, contactID } })
export const newMessage = newMessage => ({ type: "CHAT:NEW_MESSAGE", payload: newMessage });

export const updateCurrentVisitorData = (contactID, data) => (dispatch, getState) => {
    let isCurrent = (getState().activeChat.activeContactID === contactID)
    if (isCurrent) {
        dispatch({ type: "VISITOR:UPDATE", payload: { contact: data, contactID } })
    }
}

export const newExternalMessage = newMessageData => (dispatch, getState) => {
    let isCurrent = (getState().activeChat.activeContactID === newMessageData.contactID)
    let supportID = getState().user?.userDetails?.id

    if (!newMessageData.isSupportResponse && newMessageData.status !== "askly_assistant") {
        if (!newMessageData.currentAssigneeID || newMessageData.currentAssigneeID === supportID) {
            let message = (newMessageData.attachments && newMessageData.attachments.length ? newMessageData.attachments[0].translationText : newMessageData.messageContent)
            pageTitleNotification.NewMsgNotification(message, () => { dispatch(fetchChatData(newMessageData.contactID)) })
        }
    }

    newMessageData.isCurrent = isCurrent;
    newMessageData.isAssignedToMe = newMessageData.currentAssigneeID === getState().user.userDetails?.id;
    dispatch({ type: "CHAT:NEW_EXTERNAL_MESSAGE", payload: newMessageData })
};


export const messsagesUpdate = (messages, contactID) => (dispatch, getState) => {
    let isCurrent = (getState().activeChat.activeContactID === contactID)
    if (isCurrent) {
        dispatch({ type: "CHAT:MULTI_MESSAGE_UPDATE", payload: messages })
    }
};

export const messsageUpdate = message => (dispatch, getState) => {
    let isCurrent = (getState().activeChat.activeContactID === message?.contactID)
    if (isCurrent) {
        dispatch({ type: "CHAT:MESSAGE_UPDATE", payload: message })
    }
};
export const setChatSeen = (contactID, sourceType, lastSeenMessage) => (dispatch, getState) => {
    wsEventManager.sendEvent({
        eventType: "CHAT:SUPPORT_SET_SEEN_AT",
        seenAt: lastSeenMessage,
        chat: { contactID, sourceType }
    })
    dispatch({ type: "CHAT:SUPPORT_SET_SEEN_AT", payload: { contactID } })
}

// We send message to WS and add it chat. Upon ws accept this msg will be marked as sent (TODO)
export const sendMessage = (contactID, sourceType, messageContent, attachments) => (dispatch, getState) => {
    let message = { contactID, sourceType, messageContent, attachments, isSupportResponse: true }
    wsEventManager.sendEventWithCB({ eventType: "CHAT:NEW_MESSAGE", message }).then(r => {
        dispatch(newMessage(message))
        dispatch({ type: "CHAT:SUPPORT_SET_SEEN_AT", payload: { contactID } })
    }).catch(e => {
        addErrorToast(i18next.t("Could not send message"))
    })
}

export const assignChatSuccess = (contactID, currentAssigneeID, onlyPopup) => (dispatch, getState) => {
    if (contactID === getState().activeChat.activeContactID) {
        let users = getState().user.siteDetails.siteUsers?.map(siteUser => siteUser.user) || []
        let assignedUser = users.find(u => u.id === currentAssigneeID)
        let name = (assignedUser && assignedUser.name) ? assignedUser.name : "Unknown"

        addInfoToast(i18next.t("This conversation has been assigned to {{name}}", { name }))
        if (!onlyPopup) dispatch({ type: "CHAT:CHAT_ASSIGNED", payload: { contactID, currentAssigneeID } })
    }
}

// We send message to WS and add it chat. Upon ws accept this msg will be marked as sent (TODO)
export const assignChat = (contactID, currentAssigneeID) => (dispatch, getState) => {
    wsEventManager.sendEventWithCB({ eventType: "CHAT:ASSIGN", assignment: { contactID, currentAssigneeID } }).then(response => {
        dispatch(assignChatSuccess(contactID, currentAssigneeID))
        dispatch(fetchChatList())
    }).catch(e => {
        console.error(e)
        addErrorToast(i18next.t("Could not assign chat"))
    })
}

// We send message to WS and add it chat. Upon ws accept this msg will be marked as sent (TODO)
export const assignChatInit = (contactID, supportLang, visitorLang) => (dispatch, getState) => {
    let currentAssigneeID = getState().user.userDetails.id
    function chatAssigner(supportLang) { return { type: "CHAT:INIT_CHAT_ASSIGNED", payload: { supportLang, contactID, currentAssigneeID } } }
    function finishFetchChatMessages(data) { return { type: "CHAT:FINISH_MESSAGES_FETCH", payload: data } }

    wsEventManager.sendEventWithCB({ eventType: "CHAT:ASSIGN_TO_ME", assignment: { contactID, supportLang, visitorLang } }).then(res => {
        dispatch(chatAssigner(supportLang))
        dispatch(fetchChatList())
        if (res.response) dispatch(finishFetchChatMessages(res.response.reverse()))
    }).catch(e => {
        console.error(e)
        addErrorToast(i18next.t("Could not assign chat"))
    })
}

export const deleteContact = (contactID) => (dispatch, getState) => {
    dispatch({ type: "CHAT:DELETED", payload: { contactID } })
}

export const blockChat = (contactID, isBlocked) => (dispatch, getState) => {
    if (isBlocked) {
        addModal(<ConfirmModal />, {
            data: {
                title: i18next.t("Visitor has been already blocked"),
                body: i18next.t("Visitor is no longer allowed to send any new messages."),
                hideConfirm: true,
            },
            onConfirm: () => { }
        })
    } else {
        addModal(<ConfirmModal />, {
            data: {
                title: i18next.t("Block visitor ?"),
                body: i18next.t("Are you sure you want to block this visitor from sending any more messages?"),
                confirmText: i18next.t("Block"),
            },
            onConfirm: () => {
                wsEventManager.sendEventWithCB({ eventType: "CHAT:BLOCK", contactID }).then(result => {
                    dispatch({ type: "CHAT:BLOCK", payload: { contactID } })
                    dispatch(fetchChatList())
                }).catch(e => {
                    addErrorToast(i18next.t("Could not archive chat"))
                })

                //this.props.dispatch(blockChat(this.props.activeChat.activeContactID))
            }
        })
    }
}

export const archiveChat = (contactID) => (dispatch, getState) => {
    function archive() { return { type: "CHAT:ARCHIVE", payload: { contactID } } }

    wsEventManager.sendEventWithCB({ eventType: "CHAT:ARCHIVE", contactID }).then(result => {
        dispatch(archive())
        dispatch(fetchChatList())
    }).catch(e => {
        addErrorToast(i18next.t("Could not archive chat"))
    })
}

export const openChat = (contactID) => (dispatch, getState) => {
    function openChat() { return { type: "CHAT:RE_OPEN", payload: { contactID } } }

    wsEventManager.sendEventWithCB({ eventType: "CHAT:RE_OPEN", contactID }).then(result => {
        dispatch(openChat())
        dispatch(fetchChatList())
    }).catch(e => {
        addErrorToast(i18next.t("Could not reopen chat"))
    })
}

export const refreshChatData = (contactID) => (dispatch, getState) => {
    if (contactID === getState().activeChat.activeContactID) {
        dispatch(fetchChatData(contactID, { skipClearing: true }))
    }
}

export const fetchChatData = (contactID, { skipClearing = false, scrollToMessageID = null } = {}) => (dispatch, getState) => {
    function loadNewChat(contactID) { return { type: "CHAT:START_FETCH", payload: contactID } }
    function failFetchChatMessages(error) { return { type: "CHAT:FAIL_FETCH", payload: error } }
    function finishFetchChatData(data) { return { type: "CHAT:FINISH_CHAT_FETCH", payload: data } }

    // If no chatID is specified we just refresh current chat
    if (!contactID)
        contactID = getState().activeChat.activeContactID

    if (!skipClearing) dispatch(loadNewChat(contactID));
    return wsEventManager.sendEventWithCB({ eventType: "FETCH:MESSAGES", contactID }).then(data => {
        let lastMessage = data.response.messages[0]
        let contact = data.response.contact
        if (lastMessage && (!contact.supportLastSeenMessageTime || (new Date(contact.supportLastSeenMessageTime) < new Date(lastMessage.createdAt))))
            dispatch(setChatSeen(contactID, contact.sourceType, lastMessage.createdAt))

        dispatch(finishFetchChatData({ contact: data.response.contact, messages: data.response.messages.reverse(), scrollToMessageID }))
    }).catch(e => {
        addErrorToast(i18next.t("Could not fetch messages"))
        dispatch(failFetchChatMessages())
    })
};

const activeChatreducer = (state = initialState, action) => {
    switch (action.type) {
        case "SITE:CHANGE_SITE":
            return { ...initialState };
        case "CHAT:RESET":
            return { ...initialState };
        case "CHAT:START_FETCH":
            return { ...state, loading: true, activeContactID: action.payload, unreadMessages: 0 };
        case "CHAT:ADD_TAG":
            if (state.activeContactID === action.payload.contactID) {
                let newTags = [...state.contact.tags || []] // Clone array so prevProps wouldn't update also
                newTags.push(action.payload.tagging)
                return { ...state, contact: { ...state.contact, tags: newTags } };
            }
            return state
        case "CHAT:REMOVE_TAG":
            if (state.activeContactID === action.payload.contactID) {
                let newRemovedTags = [...state.contact.tags || []] // Clone array so prevProps wouldn't update also
                newRemovedTags = newRemovedTags.filter((tagging) => tagging.tag.id !== action.payload.tagId)
                return { ...state, contact: { ...state.contact, tags: newRemovedTags } }; // Cant return same object or components wont be updated as it has same ref
            }
            return state
        case "CHAT:NEW_MESSAGE":
            let newMessages = [...state.messages] // Clone array so prevProps wouldn't update also
            newMessages.push({ ...action.payload, isSupportResponse: true })
            return { ...state, messages: newMessages }; // Cant return same object or components wont be updated as it has same ref
        case "CHAT:NEW_EXTERNAL_MESSAGE":
            if (action.payload.contactID === state.activeContactID) {
                let newMessages = [...state.messages] // Clone array so prevProps wouldn't update also
                newMessages.push(action.payload)
                return { ...state, messages: newMessages, isVisitorWriting: false, unreadMessages: (action.payload.isSupportResponse ? 0 : (state.unreadMessages) + 1) }; // Cant return same object or components wont be updated as it has same ref
            }
            return state;
        case "CHAT:MULTI_MESSAGE_UPDATE":
            action.payload?.forEach?.(updatedMessage => {
                let foundMessage = state.messages.find(m => m.id === updatedMessage.id)
                if (foundMessage) {
                    foundMessage.attachments = updatedMessage.attachments
                    foundMessage.messageContent = (updatedMessage.isDeleted ? "" : foundMessage.messageContent)
                    foundMessage.isDeleted = updatedMessage.isDeleted
                }
            });

            return { ...state }
        case "CHAT:MESSAGE_UPDATE":
            let tempMessage = { messageContent: action.payload.messageContent, attachments: action.payload.attachments, isDeleted: action.payload.isDeleted, isEdited: action.payload.isEdited }
            return { ...state, messages: state.messages.map(m => m.id === action.payload.id ? { ...m, ...tempMessage } : m) };
        case "CHAT:DELETED":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, contact: {}, activeContactID: 0, isVisitorWriting: false, messages: [] };
        case "CHAT:BLOCK":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, contact: { ...state.contact, status: "done", isBlocked: true } };
            return state;
        case "CHAT:ARCHIVE":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, contact: { ...state.contact, status: "done" } };
            return state;
        case "CHAT:VISITOR_SET_SEEN_AT":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, contact: { ...state.contact, visitorLastSeenMessageTime: action.payload.seenAt } };
            return state;
        case "CHAT:SUPPORT_SET_SEEN_AT":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, unreadMessages: 0 };
            return state;
        case "CHAT:RE_OPEN":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, contact: { ...state.contact, status: "pending", currentAssigneeID: 0 } };

            return state;
        case "CHAT:CHAT_ASSIGNED":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, contact: { ...state.contact, currentAssigneeID: action.payload.currentAssigneeID, status: "assigned" } };

            return state;
        case "CHAT:INIT_CHAT_ASSIGNED":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, contact: { ...state.contact, status: "assigned", supportLang: action.payload.supportLang, currentAssigneeID: action.payload.currentAssigneeID } };

            return state;
        case "CHAT:FINISH_CHAT_FETCH":
            return { ...state, ...action.payload, loading: false };
        case "CHAT:FINISH_MESSAGES_FETCH":
            return { ...state, loading: false, messages: action.payload, error: null };
        case "CHAT:FAIL_FETCH":
            return { ...state, contact: {}, loading: false, error: action.payload };
        case "CHAT:ONLINE":
        case "CHAT:OFFLINE":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, contact: { ...state.contact, isConnected: (action.type === "CHAT:ONLINE") } };

            return state
        case "CHAT:WRITING_START":
        case "CHAT:WRITING_STOP":
            if (action.payload.contactID === state.activeContactID)
                return { ...state, isVisitorWriting: action.type === "CHAT:WRITING_START" };

            return state;
        case "CHAT:SET_LANGS":
            if (action.payload.contactID === state.activeContactID) {
                let newChat = { ...state.contact }
                if (action.payload.visitorLang) newChat.visitorLang = action.payload.visitorLang
                if (action.payload.supportLang) newChat.supportLang = action.payload.supportLang
                return { ...state, contact: newChat };
            }

            return state;
        case "VISITOR:NAME":
            if (action.payload.contactID === state.activeContactID) {
                return { ...state, contact: { ...state.contact, name: action.payload.name } }
            }
            return state;
        case "VISITOR:EMAIL":
            if (action.payload.contactID === state.activeContactID) {
                return { ...state, contact: { ...state.contact, email: action.payload.email } }
            }
            return state;
        case "VISITOR:PHONE_NR":
            if (action.payload.contactID === state.activeContactID) {
                return { ...state, contact: { ...state.contact, phoneNumber: action.payload.phone } }
            }
            return state;
        case "VISITOR:PAGE_VISIT":
            if (action.payload.contactID === state.activeContactID) {
                let locations = state.contact?.locations ? [...state.contact.locations] : [];
                locations.unshift(action.payload)
                return { ...state, contact: { ...state.contact, locations } }
            }
            return state;
        case "VISITOR:NOTES":
            if (action.payload.contactID === state.activeContactID) {
                return { ...state, contact: { ...state.contact, notes: action.payload.notes } }
            }
            return state;
        case "VISITOR:UPDATE":
            if (action.payload.contactID === state.activeContactID) {
                return { ...state, contact: Object.assign({}, state.contact, action.payload.contact) }
            }
            return state;
        default:
            return state;
    }
};

export default activeChatreducer;