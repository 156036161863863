import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { getLanguage } from '../../logic/util';
import illustration from '../../resources/illustration-6.png';
import { useQuickRepliesQuery } from '../../state/api';
import { addModal } from '../modals/ModalManager';
import { QRManage } from '../modals/QuickReplyManagement';
import './QuickReply.scss';
import { ThinButton } from './minor/Common';
import useOutsideClick from './useOutsideClick';

function QuickReplyModal({ match, currentLanguage = "en", onSelect, close }) {
    const [showGS, setShowGS] = useState(true);
    const [searchFilter, setSearchFilter] = useState("")
    const { t } = useTranslation("quick_reply")
    const { data: qReplies, isLoading, isSuccess } = useQuickRepliesQuery();

    useEffect(() => {
        if (isSuccess && qReplies?.length > 0)
            setShowGS(false)
    }, [qReplies, isSuccess])

    const openQRManager = (openNew) => {
        addModal(<QRManage />, {
            data: { openNew }
        })
        close();
    }

    const selectQR = (qr) => {
        onSelect(qr)
    }

    if (isLoading) {
        return (
            <div className='qr-modal loading'>
            </div>
        )
    }


    // Getting started
    if (showGS)
        return (
            <div className='qr-modal get-started'>
                <div className='top-section'>
                    <div className='my-auto mr-2'>
                        <div className='title-txt'>{t("Quick Replies")}</div>
                        <div className='sub-txt'>{t("Add replies, use them to speed up the chat")}</div>
                    </div>
                    <div className='illustration-wrap'>
                        <img className='illustration' src={illustration} alt="illustration" />

                    </div>
                </div>

                <div className='d-flex flex-column flex-grow-1 '>
                    <Button className='m-auto' onClick={(e) => { e.stopPropagation(); setShowGS(false) }}>
                        {t("Get started")}
                    </Button>
                </div>
            </div>
        )



    let replies = searchFilter ? qReplies.filter((qr) => {
        return qr.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
            qr.value?.[currentLanguage]?.toLowerCase().includes(searchFilter.toLowerCase())
    }) : qReplies

    return (
        <div className='qr-modal list-view'>
            <div className='top-row d-flex px-3 py-3 align-items-center'>
                <div className='title-txt'>{t("Quick Replies")}</div>
                <ThinButton className="ml-auto" onClick={() => { openQRManager(false) }}>{t("Manage")}</ThinButton>
            </div>
            <div className='px-3 '>
                <Form.Control type="text" placeholder={t("Search for Quick Replies")} value={searchFilter} maxLength={55} onChange={e => { setSearchFilter(e.target.value) }} />
            </div>
            <div className='qr-content d-flex flex-column flex-grow-1  px-3 my-2 '>
                {qReplies.length === 0 && <div className='no-qr-placeholder d-flex flex-grow-1 m-auto  flex-column'>
                    <div className='mb-2 mx-auto'>{t("No quick replies created")}</div>
                    <div className='mx-auto'>
                        <ThinButton type="primary" onClick={() => { openQRManager(true) }}>{t("Create Reply")}</ThinButton>
                    </div>
                </div>}
                {searchFilter && replies.length === 0 && <div className='no-qr-placeholder d-flex flex-grow-1 m-auto  flex-column'>
                    <div className='mb-2 mx-auto'>{t("No quick replies found")}</div>
                </div>}
                {replies.map(q => {
                    const hasLanguage = (q.value?.[currentLanguage] ? true : false)

                    return (<div key={q.id} className='qr-row' onClick={() => { selectQR(q) }}>
                        <div className='title'>{!hasLanguage && <span className='missing'>(Missing {getLanguage(currentLanguage, "iso").eng})</span>} {q.name}</div>
                        <div className='value'>{q.value?.[currentLanguage] || "..."}</div>
                    </div>)
                })}
            </div>
        </div >
    )

}

export function QuickReplyToggle({ match, currentLanguage, onQRSelect }) {
    const [show, setShow] = useState(false)
    const wrapperRef = useRef(null);

    useOutsideClick(wrapperRef, () => {
        if (show)
            setShow(false)
    })

    return (
        <div ref={wrapperRef} className='qr-toggle'>
            <div onClick={() => { setShow(!show) }}>
                <i id="qr-toggle-icon" style={{ userSelect: 'none' }} className={cn("area-action fa-bookmark", { 'far': !show, 'fas': show, 'is-open': show })}></i>
            </div>
            {show && <QuickReplyModal currentLanguage={currentLanguage} close={() => { setShow(false) }} onSelect={(qr) => { setShow(false); onQRSelect(qr) }} />}
        </div>
    )
}