import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { extractInitials, toLowerAndCapitalize } from '../../../logic/util';
import { useLeaveSiteMutation, useRemoveSiteUserMutation, useSaveSiteUserMutation } from '../../../state/api';
import { fetchSiteDetails } from '../../../state/currentUser';
import { addErrorToast, addInfoToast } from '../../base/ToastManager';
import ConfirmModal from '../../modals/ConfirmModal';
import InviteTeamUserModal from '../../modals/InviteTeamUserModal';
import { addModal } from '../../modals/ModalManager';
import { DefaultSettingsWrap } from './Base';


export default function SiteMembers() {
    const dispatch = useDispatch()
    const { t } = useTranslation("settings_members")
    const siteDetails = useSelector(state => state.user.siteDetails)
    const userDetails = useSelector(state => state.user.userDetails)

    let siteUsers = siteDetails.siteUsers && siteDetails.siteUsers.map((el, i) => (<TeamMemberRow key={el.user.id} isMe={el.userID === userDetails.id} data={el} siteRole={userDetails.currentSiteRole} />))

    return (
        <DefaultSettingsWrap
            subPageTitle={t("Team members", { ns: 'settings_nav' })}
            buttons={(<>
                {["OWNER", "ADMIN"].includes(userDetails.currentSiteRole) && <Button variant="success" className="float-right" onClick={() => { addModal(<InviteTeamUserModal />, { onInvited: () => { dispatch(fetchSiteDetails()) } }) }}>{t("Add new")}</Button>}
            </>)}>
            <Form>
                <div>{siteUsers}</div>
            </Form>
        </DefaultSettingsWrap>
    )
}



function TeamMemberRow(props) {
    const dispatch = useDispatch()
    const { t } = useTranslation("settings_members")
    const [removeSiteUser] = useRemoveSiteUserMutation();
    const [leaveSite] = useLeaveSiteMutation();
    const [saveSiteUser] = useSaveSiteUserMutation();
    let currentRole = props.data.role ? toLowerAndCapitalize(props.data.role) : "MEMBER"
    let showDropdown = props.data.role !== "OWNER" && ["OWNER", "ADMIN"].includes(props.siteRole)

    let changeRoleTo = (role) => {
        let email = props.data.user.email;
        saveSiteUser({ email, role }).unwrap().then(r => {
            addInfoToast(t("User role changed"))
            dispatch(fetchSiteDetails())
        }).catch(e => {
            addErrorToast(t("Something went wrong. Could not invite user"))
        })
    }

    function leaveUser() {
        addModal(<ConfirmModal />, {
            data: {
                title: t("Leave team ?"),
                body: t("Are you sure you want to leave the team ?"),
                includeLoading: true,
            },
            onConfirm: () => {
                return leaveSite().unwrap().then(() => {
                    addInfoToast(t("Left the team"))
                    // TODO CRIT CHANGE Team
                    window.location.href = '/api/me/switch';
                }).catch(() => {
                    addErrorToast(t("Error removing user"))
                })
            }
        })
    }
    function removeUser() {
        addModal(<ConfirmModal />, {
            data: {
                title: t("Remove user ?"),
                body: t("Are you sure you want to remove {{email}} from team ?", { email: props.data.user.email }),
                includeLoading: true,
            },
            onConfirm: () => {
                return removeSiteUser(props.data.userID).unwrap().then(() => {
                    addInfoToast(t("User removed"))
                    dispatch(fetchSiteDetails())
                }).catch(() => {
                    addErrorToast(t("Error removing user"))
                })
            }
        })
    }

    return (
        <div className="member d-flex align-items-center mb-3">
            <div className="author d-flex align-items-center">
                <div className="avatar d-flex align-items-center justify-content-center">
                    {extractInitials(props.data.user.name)}
                </div>
                <div>
                    <p className={props.data.user.name ? "author-name" : "author-name pending"}>{props.data.user.name ? props.data.user.name : t("Invite pending")}</p>
                    <p className="email">{props.data.user.email}</p>
                </div>
                <div className="actions ml-auto"></div>
            </div>
            <div className="dropdown add-new-team-member ml-auto d-flex">
                {(props.isMe && props.data.role !== "OWNER") && (<Button className="mr-4" variant="outline-primary" onClick={leaveUser}>{t("Leave")}</Button>)}
                {!props.isMe && showDropdown && <span className='mr-3'>{currentRole}</span>}
                {showDropdown &&
                    <DropdownButton title={<i className="far fa-ellipsis-v"></i>} menuAlign="right">
                        {props.data.role !== "ADMIN" && <Dropdown.Item onClick={() => { changeRoleTo("ADMIN") }}>{t("Make admin")}</Dropdown.Item>}
                        {props.data.role !== "MEMBER" && <Dropdown.Item onClick={() => { changeRoleTo("MEMBER") }}>{t("Make member")}</Dropdown.Item>}
                        <Dropdown.Item onClick={removeUser}>{t("Remove")}</Dropdown.Item>
                    </DropdownButton>}
                {!showDropdown && <span>{currentRole}</span>}

            </div>
        </div>
    )
}
