import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { asQueryString } from '../../logic/api';
import { useAppLoginMutation } from "../../state/api";
import './SignUp.scss';

export default function SignInApp(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [urlData, setUrlData] = useState({});
    const [appLogin] = useAppLoginMutation();

    function parseURL(params) {
        // TODO Cache urlParams for failed logins or reattach server side
        let urlParams = new URLSearchParams(props.location.search)
        let redirectBase = urlParams.get("redirectBase")
        let deviceUUID = urlParams.get("deviceUUID")
        let deviceType = urlParams.get("deviceType")
        let deviceName = urlParams.get("deviceName")
        let errorCode = urlParams.get("e")

        if (errorCode) {
            setErrorByCode(atob(errorCode))
        }

        setUrlData({ redirectBase, deviceUUID, deviceType, deviceName })
    }

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (email.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { email: "Email is required" })
            validated = false;
        }

        if (password.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { password: "Password is required" })
            validated = false;
        }

        setErrors(tmpErrors)
        return validated
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (validateForm()) {
            appLogin({ email, password, ...urlData }).unwrap().then(response => {
                window.location.href = response
            }).catch(err => {
                setErrorByCode(err?.statusCode)
            });
        }
    }

    function setErrorByCode(errorCode) {
        if (errorCode) {
            if (errorCode === "PWD_WRONG") {
                setErrors({ password: "Wrong password" })
            } else if (errorCode === "NO_USER") {
                setErrors({ email: "No such user" })
            } else if (errorCode === "NO_SITES") {
                setErrors({ general: "Finish setup on desktop" })
            } else if (errorCode === "EMAIL_UNVERIFIED") {
                setErrors({ email: "Email unverified" })
            } else {
                setErrors({ general: "Something went wrong. Try again later." })
            }
        } else {
            setErrors({ general: "Something went wrong. Try again later." })
        }
    }

    useEffect(() => {
        parseURL();
    }, [])


    return (<div className="mob auth-view" style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
            <form onSubmit={handleSubmit} autoComplete="off">
                <h1 className="login-title">Log in</h1>
                <p className="login-details mb-0">We'll streamline your setup experience accordingly.</p>
                <p className="general-error mb-3 text-center">{errors.general}</p>

                <div className="mx-auto text-left d-flex flex-column" >
                    <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control name="email" type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
                        <Form.Text className="error-text-sm">{errors.email}</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control name="password" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                        <Form.Text className="error-text-sm">{errors.password}</Form.Text>
                    </Form.Group>
                    <input name="redirectBase" value={urlData?.redirectBase} hidden />
                    <input name="deviceUUID" value={urlData?.deviceUUID} hidden />
                    <input name="deviceType" value={urlData?.deviceType} hidden />
                    <input name="deviceName" value={urlData?.deviceName} hidden />
                    <button className="df-btn mx-auto px-5 py-2" style={{ marginTop: "22px" }}>Login</button>
                </div>
            </form>

            <div className="sidelines"><span>OR</span></div>
            <div className="social-grid">
                <a href={"/api/login/google?" + asQueryString({ isApp: true, ...urlData })} className="df-btn df-btn-inverse google">Continue with Google</a>
                <a href={"/api/login/facebook?" + asQueryString({ isApp: true, ...urlData })} className="df-btn df-btn-inverse facebook">Continue with Facebook</a>
                <a href={"/api/login/apple?" + asQueryString({ isApp: true, ...urlData })} className="df-btn df-btn-inverse apple">Continue with Apple</a>
            </div>
            <p className="tos-agree-text">By clicking Log In, I confirm that I have read and agree to the Askly Terms of Service, Privacy Policy, and to receive emails and updates.</p>
        </div>
    </div>)
}