import classNames from 'classnames'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import instagramIcon from '../../resources/insta_icon_240x240.png'
import { useAddPageFacebookMutation } from '../../state/api'
import { fetchSiteDetails } from '../../state/currentUser'
import { addErrorToast, addInfoToast } from '../base/ToastManager'
import './FacebookPagesModal.scss'
import './Modal.scss'


function FacebookPagesModal(props) {
    const [selectedPages, setSelectedPages] = useState([])
    const dispatch = useDispatch()
    const [addPageFacebook] = useAddPageFacebookMutation();

    const { t } = useTranslation("modals");

    function addPages(params) {
        addPageFacebook({
            userAccessToken: props.userAccessToken,
            pages: selectedPages,
        }).unwrap().then((r) => {
            addInfoToast(t("Pages added"))
            dispatch(fetchSiteDetails())
            props.onClose()
        }).catch(e => {
            addErrorToast(t("Could not add FB pagess"))
        })
    }

    function togglePage(add, pageID) {
        if (add) {
            setSelectedPages([...selectedPages.filter(selectedPage => selectedPage !== pageID), pageID])
        } else {
            setSelectedPages([...selectedPages.filter(selectedPage => selectedPage !== pageID)])
        }
    }

    return (
        <Modal size="sm" className="tw-modal fb-pages-modal" centered show={props.show} onHide={props.onClose} animation={false}>

            <div className="modal-icon">
                <i className="fab fa-facebook"></i>
            </div>
            <h5 className="modal-title text-center mt-2">{t("Facebook pages")}</h5>
            <div className="modal-sub-title text-center mt-1 mb-4">{t("Select pages to connect with current organization.")}</div>
            <div>
                {props.pages?.map(element => {
                    return (<div key={element.id}>
                        <PageListElem
                            id={element.id}
                            name={element.name}
                            picture={element?.picture?.data?.url}
                            instaName={element["connected_instagram_account"]?.name}
                            instaID={element["connected_instagram_account"]?.id}
                            isChecked={selectedPages.includes(element.id)}
                            onToggle={togglePage}
                        />
                    </div>)
                })
                }
                <div className="d-flex mt-5">
                    <Button onClick={props.onClose} variant="outline-secondary mr-auto">
                        {t("Cancel", { ns: 'common' })}
                    </Button>
                    <Button onClick={addPages} disabled={!selectedPages.length}>
                        {t("Connect pages")}
                    </Button>
                </div>
            </div>
        </Modal >
    );
}

export default FacebookPagesModal;

function PageListElem({ name, picture, id, instaName, instaID, isChecked, onToggle }) {
    return (
        <label htmlFor={"site-" + id} className={classNames("page-elem d-flex flex-row align-items-center", { 'is-selected': isChecked })} style={{ cursor: 'pointer', userSelect: 'none' }}>
            <div className='d-flex flex-column flex-grow-1 mr-2'>
                <div className="d-flex align-items-center">
                    <div style={{ flexShrink: 0 }}>
                        {picture && <img alt="" style={{ width: 24, height: 24, borderRadius: 4, marginRight: 8 }} src={picture} />}
                    </div>
                    <div>{name}</div>
                </div>
                {instaID && <div style={{ marginTop: 6, borderTop: '1px solid #ccc' }}>
                    <div className="d-flex align-items-center mt-2" >
                        <div style={{ flexShrink: 0 }}>
                            <img alt="" style={{ width: 24, height: 24, borderRadius: 4, marginRight: 8 }} src={instagramIcon} />
                        </div>
                        <div>{instaName}</div>
                    </div>
                </div>}
            </div>
            <input id={"site-" + id}
                style={{ width: 18, height: 18, marginLeft: 'auto', flexShrink: 0, marginRight: 6 }}
                type="checkbox"
                checked={isChecked}
                onChange={e => { onToggle(!isChecked, id) }} />
        </label>
    )
}