import cn from 'classnames'
import React from 'react'
import { Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { withTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { getLanguage, getLanguages, getLanguagesIso } from '../../logic/util'
import { easyFetch, endpoints } from '../../state/api'
import { addErrorToast, addInfoToast } from '../base/ToastManager'
import { ThinButton } from '../base/minor/Common'
import { SearchDropdownWithLabelPrefix } from '../base/minor/SearchDropdown'
import ConfirmModal from './ConfirmModal'
import './Modal.scss'
import { addModal } from './ModalManager'
import './QuickReplyManagement.scss'

class QRManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            activeView: props?.data?.openNew ? 'edit' : 'list',
            acceptedLanguageSelect: false,
            activelanguage: "en",
            searchFilter: "",
            selectedQRID: null,
            selectedQREditing: {},
            replies: [],
        }

        this.backToList = this.backToList.bind(this)
        this.cancelClick = this.cancelClick.bind(this)
        this.acceptLanguage = this.acceptLanguage.bind(this)
        this.getCurrentQRValue = this.getCurrentQRValue.bind(this)
        this.saveQR = this.saveQR.bind(this)
        this.deleteQR = this.deleteQR.bind(this)

        this.inputRef = React.createRef();

    }

    componentDidMount() {
        if (this.props.data.suggestedReply) {
            this.setState({
                acceptedLanguageSelect: true,
                selectedQRID: null,
                activelanguage: this.props.data.suggestedLang || 'en',
                selectedQREditing: {
                    value: {
                        [(this.props.data.suggestedLang || 'en')]: this.props.data.suggestedText,
                    }
                }
            })
        }

        this.fetchReplies()
    }

    fetchReplies(selectID) {
        easyFetch(this.props.dispatch(endpoints.quickReplies.initiate())).then(r => {
            this.setState({ replies: r, isLoading: false })
        }).catch(e => {

        })
    }

    cancelClick() {
        if (this.props.onCancel) {
            this.props.onCancel()
            this.props.onClose();
        } else {
            this.props.onClose()
        }
    }

    saveQR() {
        const { t } = this.props

        if (this.state.isSaving) {
            return
        }

        if (!this.state.selectedQREditing.name) {
            addErrorToast(t("Invalid quick reply"))
            return
        }

        //
        if (!this.state.selectedQREditing.value) {
            addErrorToast(t("Invalid quick reply"))
            return
        }

        if (this.state.selectedQREditing.id) {
            easyFetch(this.props.dispatch(endpoints.updateQuickReply.initiate(this.state.selectedQREditing))).then((r) => {
                this.fetchReplies(this.state.selectedQREditing.id)
                addInfoToast(t("Quick reply saved"))
            }).catch((e) => {
                addErrorToast(t("Could not save quick reply"))
            })
        } else {
            this.setState({ isSaving: true })
            easyFetch(this.props.dispatch(endpoints.newQuickReply.initiate(this.state.selectedQREditing))).then((r) => {
                this.setState({ selectedQRID: r.id, selectedQREditing: r })
                addInfoToast(t("Quick reply saved"))
                this.fetchReplies()
            }).catch((e) => {
                addErrorToast(t("Could not save quick reply"))
            }).finally(() => {
                this.setState({ isSaving: false })
            })
        }
    }

    translateQR = () => {
        const { t } = this.props

        addModal(<ConfirmModal />, {
            data: {
                title: t("Translate to other languages?"),
                body: t("Your quick reply will be pre translated to all the supported languages."),
                confirmText: t("Translate"),
                includeLoading: true,
            },
            onConfirm: () => {
                let sourceLang = this.state?.activelanguage
                let text = this.state?.selectedQREditing?.value[sourceLang]
                if (!text) {
                    addErrorToast(t("Nothing to translate"))
                    return
                }
                easyFetch(this.props.dispatch(endpoints.getManyTranslations.initiate({ text, sourceLang, targetLangs: getLanguagesIso() }))).then(results => {
                    let selectedQREditing = { ...this.state.selectedQREditing, value: { ...this.state?.selectedQREditing?.value, ...results } }
                    this.setState({ selectedQREditing })
                    addInfoToast(t("Replies translated"))
                }).catch(e => {
                    addErrorToast(t("Could not translate"))
                })
            },
        }, true)
    }

    deleteQR() {
        const { t } = this.props
        addModal(<ConfirmModal />, {
            data: {
                title: t("Delete quick reply?"),
                body: t("Are you sure you want to delete this quick reply?"),
                confirmText: t("Delete", { ns: 'common' }),
            },
            onConfirm: () => {
                easyFetch(this.props.dispatch(endpoints.deleteQuickReply.initiate(this.state.selectedQREditing))).then(() => {
                    addInfoToast(t("Quick reply deleted"))
                    this.setState({
                        selectedQRID: null,
                        selectedQREditing: {},
                        activeView: 'list',
                    })
                }).catch(() => {
                    addErrorToast(t("Could not delete quick reply"))
                }).finally(() => {
                    this.fetchReplies()
                })
            }
        }, true)
    }

    selectLang(lang) {
        this.setState({ activelanguage: lang })
    }

    backToList() {
        this.setState({ activeView: 'list', selectedQRID: null })
    }

    selectQR(id) {
        if (!id) {
            this.setState({ activeView: 'edit', selectedQRID: null, selectedQREditing: ({}), acceptedLanguageSelect: false })
        } else {
            let tmp = this.state?.replies.find(r => r.id === id)
            this.setState({ activeView: 'edit', selectedQRID: id, selectedQREditing: (tmp || {}) })
        }
    }

    getCurrentQRValue() {
        return this.state?.selectedQREditing?.value?.[this.state?.activelanguage] || ""
    }

    editQRValue(lang) {
        return (e) => {
            let selectedQREditing = { ...this.state.selectedQREditing, value: { ...this.state?.selectedQREditing?.value, [lang]: e.target?.value } }
            this.setState({ selectedQREditing })
        }
    }

    acceptLanguage() {
        this.setState({ acceptedLanguageSelect: true })
        this.inputRef?.current.focus();
    }

    render() {
        const { t } = this.props
        const { isLoading, acceptedLanguageSelect } = this.state;
        const hasReplies = this.state.replies?.length > 0
        const selectedLang = getLanguage(this.state?.activelanguage, "iso").eng
        const canWrite = acceptedLanguageSelect || this.state?.selectedQREditing.id

        const replies = this.state.searchFilter ? this.state.replies.filter((qr) => {
            return qr.name.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
                qr.value?.[this.state?.activelanguage]?.toLowerCase().includes(this.state.searchFilter.toLowerCase())
        }) : this.state.replies;

        if (this.state.activeView === 'edit') {
            return (
                <Modal className="tw-modal qr-managment-content-modal" centered size='md' show={this.props.show} onHide={this.props.onClose} animation={false}>
                    <div className='d-flex px-3 mb-2'>
                        <h5 className="modal-title my-2">{t("Quick Replies")}</h5>
                        <ThinButton className="ml-auto bg-none" onClick={this.backToList}>{t("Back", { ns: 'common' })}</ThinButton>
                    </div>
                    <div className='qr-managment-content'>
                        <div className='qr-edit mt-2 px-3'>
                            <div className='d-flex flex-column'>
                                <Form.Group>
                                    <Form.Label>{t("Reply name")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t("Add reply name")}
                                        value={this.state?.selectedQREditing?.name || ""}
                                        onChange={e => { this.setState({ selectedQREditing: { ...this.state?.selectedQREditing, name: e.target.value } }) }} />
                                </Form.Group>
                                <Form.Group>
                                    <div className='d-flex mb-2 align-items-center'>
                                        <Form.Label className='mb-0'>{t("Reply content")}</Form.Label>
                                        <ThinButton className="ml-auto" onClick={this.translateQR}>{t("Translate")}</ThinButton>
                                        <SearchDropdownWithLabelPrefix className="mb-0 limit-len ml-2" label="Language" currentSelect={selectedLang} allSelection={getLanguages()} onSelect={(langName) => { this.selectLang(getLanguage(langName, "eng").iso) }} />
                                    </div>
                                    <div className='position-relative'>
                                        <Form.Control
                                            ref={this.inputRef}
                                            className="main-reply"
                                            as="textarea"
                                            type="text"
                                            rows="5"
                                            maxLength={1100}
                                            disabled={!canWrite}
                                            placeholder={canWrite ? t("Add reply content") : ""}
                                            value={this.state?.selectedQREditing?.value?.[this.state?.activelanguage] || ""}
                                            onChange={this.editQRValue(this.state?.activelanguage)} />
                                        {!canWrite && <div className='position-absolute d-flex' style={{ inset: 0 }}>
                                            <ThinButton className="m-auto" onClick={this.acceptLanguage}>{t("Add reply in {{selectedLang}}", { selectedLang })}</ThinButton>
                                        </div>}
                                    </div>
                                </Form.Group>
                                <div className='d-flex mb-2'>
                                    <div className='ml-auto'></div>
                                    {this.state?.selectedQREditing.id && <ThinButton className="ml-auto" onClick={this.deleteQR}>{t("Delete", { ns: 'common' })}</ThinButton>}
                                    <ThinButton type="primary" className="ml-2" disabled={this.state.isSaving} onClick={this.saveQR}>{t("Save", { ns: 'common' })}</ThinButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }

        return (
            <Modal className="tw-modal qr-managment-content-modal" centered size='lg' show={this.props.show} onHide={this.props.onClose} animation={false}>
                <div className='d-flex px-3 mb-2'>
                    <h5 className="modal-title my-2">{t("Quick Replies")}</h5>
                    <SearchDropdownWithLabelPrefix className="mb-0 limit-len ml-auto static-width" label="Language" currentSelect={selectedLang} allSelection={getLanguages()} onSelect={(langName) => { this.selectLang(getLanguage(langName, "eng").iso) }} />
                    <ThinButton className="ml-2" onClick={() => { this.selectQR(null) }}>{t("New reply")}</ThinButton>
                </div>
                <div>
                    <div className='px-3 '>
                        <Form.Control type="text" placeholder={t("Search for Quick Replies")} value={this.state.searchFilter} maxLength={55} onChange={e => { this.setState({ searchFilter: e.target.value }) }} />
                    </div>
                </div >
                <div className='qr-nav'>
                    {!isLoading && replies.length === 0 && <div className='no-qr-placeholder d-flex flex-grow-1 m-auto  flex-column'>
                        <div className='m-auto'>{t("No quick replies found")}</div>
                    </div>}
                    {hasReplies && replies.map((reply) => {
                        return (<div key={reply.id} className={cn("qr-elem-row", { active: (reply.id === this.state.selectedQRID) })} onClick={() => { this.selectQR(reply.id) }}>
                            <div className='titlle'>{reply.name}</div>
                            <div className='sub-txt'>{(reply.value?.[this.state?.activelanguage] || "...")}</div>
                        </div>)
                    })}
                </div>
            </Modal>
        );
    }
}

export const QRManage = withTranslation("modal_qrm")(connect()(QRManageModal))