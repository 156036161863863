import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toFormatedDateString } from '../../logic/util';
import './Modal.scss';
import './VisitedPagesModal.scss';


export default function VisitedPagesModal(props) {
    const { t } = useTranslation("chat")
    return (
        <Modal className="tw-modal visited-pages-modal" centered show={props.show} onHide={props.onClose} animation={false}>
            <h5 className="modal-title my-2">{t("Visited pages")}</h5>
            <div className="locations-wrap px-3">

                {props.data?.locations?.map((location, i) => (
                    <div className="location-row" key={i}>
                        <div className="location-header">
                            <div className="visit-page-title">{location.pageTitle}</div>
                            <div className="visit-time ml-auto">{location.visitStart && toFormatedDateString(location.visitStart, false, false, true)}</div>
                        </div>
                        <div className="location-url"><a href={location.pageURL} target="_blank" rel="noreferrer">{location.pageURL}</a></div>
                    </div>
                ))}
            </div>
        </Modal >
    );
}