import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation, withTranslation } from "react-i18next";
import { connect, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { isValidURL } from '../../logic/util';
import wsEventManager from '../../logic/wsEventmanager';
import { easyFetch, endpoints } from '../../state/api';
import { fetchMyDetails } from '../../state/currentUser';
import { addErrorToast } from '../base/ToastManager';
import GuideButton from '../base/minor/GuideButton';
import { addModal } from '../modals/ModalManager';
import SendToDeveloperModal from "../modals/SendToDeveloperModal";
import WelcomeModal from "../modals/WelcomeModal";
import DefaultLayout from './DefaultLayout';
import './SetupPage.scss';

class SetupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            teamName: "",
            webURL: "",
            loading: false,
        }

        this.createNewOrg = this.createNewOrg.bind(this);
        this.cancelSetup = this.cancelSetup.bind(this);
    }

    componentDidMount() {
        // this.props.dispatch(fetchMyDetails())
    }

    createNewOrg() {
        const { t } = this.props;
        this.setState({ loading: true }, () => {
            easyFetch(this.props.dispatch(endpoints.newOrg.initiate({ orgName: this.state.teamName, orgURL: this.state.webURL }))).then(r => {
                this.props.dispatch(fetchMyDetails()).then(() => {
                    this.setState({ loading: false, step: 2 })
                    wsEventManager.restart()
                }).catch((e) => {
                    addErrorToast(t("Something went wrong", { ns: 'common' }))
                })
            }).catch(e => {
                addErrorToast(t("Something went wrong", { ns: 'common' }))
                this.setState({ loading: false })
            })
        })
    }

    cancelSetup() {
        this.props?.history?.push("/chats")
    }

    render() {
        let hasOtherSites = this.props.user?.userDetails?.sites?.length > 0

        return (
            <DefaultLayout hideSideBar match={this.props.match}>
                <div className={"content-card setup-page" + (this.state.step === 2 ? " lg-setup" : "")}>
                    {this.state.step === 0 && <Setup1 cancelSetup={this.cancelSetup} hasOtherSites={hasOtherSites} nextStep={() => { this.setState({ step: 1 }) }} teamName={this.state.teamName} setName={(teamName) => { this.setState({ teamName }) }} />}
                    {this.state.step === 1 && <Setup2 cancelSetup={this.cancelSetup} hasOtherSites={hasOtherSites} nextStep={this.createNewOrg} loading={this.state.loading} webURL={this.state.webURL} setWebURL={(webURL) => { this.setState({ webURL }) }} />}
                    {/*this.state.step === 2 && <Setup3 nextStep={() => { this.setState({ step: 3 }) }} siteDetails={this.props.user.siteDetails} />*/}
                    {this.state.step === 2 && <Setup4 siteDetails={this.props.user.siteDetails} webURL={this.state.webURL} hasOtherSites={hasOtherSites} />}
                </div>
            </DefaultLayout>)
    }
}

const mapStateToProps = state => ({ user: state.user });
export default withTranslation("setup")(withRouter(connect(mapStateToProps)(SetupPage)));

function Setup1({ nextStep, teamName, setName, hasOtherSites, cancelSetup }) {
    const { t } = useTranslation("setup")
    const [errorMsg, setErrorMsg] = useState("")
    let textInput = null;

    useEffect(() => {
        textInput.focus();
    })

    function tryNextStep(e) {
        e.preventDefault();

        if (!teamName) {
            setErrorMsg(t("Required"))
        } else {
            nextStep()
        }
    }

    return (
        <div className={hasOtherSites ? "mt-4" : ""}>
            {hasOtherSites && <div onClick={cancelSetup} className="cancel-setup">{t("Cancel", { ns: 'common' })}</div>}
            <div className="setup-ico">
                <i className="fas fa-comments-alt"></i>
            </div>
            <Form onSubmit={tryNextStep} >
                <h2 >{t("Enter your company name")}</h2>
                <div>
                    <Form.Group>
                        <Form.Label><span className="general-error ml-2">{errorMsg}</span></Form.Label>
                        <Form.Control type="text" placeholder="ABC Corporation" value={teamName} onChange={(e) => { setName(e.target.value) }} ref={(button) => { textInput = button; }} />
                    </Form.Group>
                </div>
                <div className="d-flex mt-4">
                    <Button className="mx-auto mt-2" onClick={tryNextStep}>{t("Continue", { ns: 'common' })}</Button>
                </div>
            </Form>
        </div>
    )
}


function Setup2({ nextStep, loading, webURL, setWebURL, hasOtherSites, cancelSetup }) {
    const { t } = useTranslation("setup")
    const [errorMsg, setErrorMsg] = useState("")
    let textInput = null;

    useEffect(() => {
        textInput.focus();
    })

    function tryNextStep(e) {
        e.preventDefault();

        if (!webURL) {
            setErrorMsg(t("Required"))
            return
        } else {
            if (!isValidURL(webURL)) {
                setErrorMsg(t("Valid domain required"))
                return
            }

            try {
                let fullURL = webURL
                if (!fullURL.startsWith("http://") && !fullURL.startsWith("https://")) {
                    fullURL = "https://" + fullURL;
                }
                let domain = (new URL(fullURL));
                let correctedSiteDomain = domain.hostname.replace('www.', '');
                setWebURL(correctedSiteDomain)
                nextStep()
            } catch (error) {
                setErrorMsg(t("Valid domain required"))
            }
        }
    }

    return (
        <div className={hasOtherSites ? "mt-4" : ""}>
            <div className="setup-ico">
                <i className="fas fa-comments-alt"></i>
            </div>
            {hasOtherSites && <div onClick={cancelSetup} className="cancel-setup">{t("Cancel", { ns: 'common' })}</div>}

            <Form onSubmit={tryNextStep}>
                <h2>{t("Enter your domain")}</h2>
                <div>
                    <Form.Group>
                        <Form.Label><span className="general-error ml-2">{errorMsg}</span></Form.Label>
                        <Form.Control type="text" placeholder="www.example.com" value={webURL} onChange={(e) => { setWebURL(e.target.value) }} ref={(button) => { textInput = button; }} />
                    </Form.Group>
                </div>
                <div className="d-flex mt-4">
                    <Button disabled={loading} className="mx-auto mt-2" onClick={tryNextStep}>{t("Continue", { ns: 'common' })}</Button>
                </div>
            </Form>
        </div>
    )
}

function Setup4({ siteDetails, webURL, hasOtherSites }) {
    const { t } = useTranslation("setup")
    const history = useHistory();

    function finish(skip) {
        history.push("/chats");
        addModal(<WelcomeModal />, {})
        /*
    if (skip) {
        history.push("/chats");
    } else {
        history.push("/settings/site/web-widget", { runTour: true });
    }
    */
    }

    function sendToDeveloperPopup() {
        addModal(<SendToDeveloperModal />, {
            data: {},
            onConfirm: () => { }
        })
    }

    return (
        <div>
            <div className="setup-ico">
                <i className="fas fa-comments-alt"></i>
            </div>
            <Form>
                <h2>{t("Almost ready!")}</h2>
                <h4 className='text-center'>{t("Choose your website platform:")}</h4>
                <div className='step-4-body'>
                    <div className="guide-btns">
                        <div className="d-flex flex-wrap justify-content-center">
                            <GuideButton type="shoproller" />
                            <GuideButton type="shopify" />
                            <GuideButton type="wordpress" />
                            <GuideButton type="woocommerce" />
                            <GuideButton type="wix" />
                            <GuideButton type="magento" />
                            <GuideButton type="voog" />
                        </div>
                    </div>
                    <div className='d-flex flex-row mx-4 align-items-center mt-4'>
                        <div >
                            <b>{t("If you use any other platform or don't have web admin access, press this:")}</b>
                        </div>
                        <div className='ml-3 flex-shrink-0'>
                            <Button onClick={sendToDeveloperPopup} className="my-auto" variant="outline-primary"><i className="fas fa-envelope mr-2" />{t("Send to developer")}</Button>
                            {!hasOtherSites && <div onClick={() => { finish(false) }} className="finish-setup">{t("Finish", { ns: 'common' })}</div>}
                        </div>
                    </div>
                    <p className='mt-4'>{t("It takes a few seconds for developers to add a script to the <head> tag on your web platform and chat is live on your site.")} </p>

                </div>
                <div className="d-flex mt-4">
                    {!hasOtherSites && <Calendly webURL={webURL} finish={finish} />}
                    {hasOtherSites && <Button className="mx-auto" onClick={() => { finish(false) }}>{t("Finish", { ns: 'common' })}</Button>}
                </div>
            </Form>
        </div>
    )
}

function Calendly({ webURL, finish }) {
    const userDetails = useSelector(state => state.user.userDetails)
    const { t } = useTranslation("setup")

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;

        const style = document.createElement('link');
        style.href = "https://assets.calendly.com/assets/external/widget.css";
        style.type = "text/css";
        style.rel = "stylesheet";
        style.media = 'all';

        function calendlyEventHandler(e) {
            if (e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0) {
                if (e.data.event === "calendly.event_scheduled")
                    finish(false)
            }
        };


        document.body.appendChild(script);
        document.head.appendChild(style);
        window.addEventListener("message", calendlyEventHandler);


        return () => {
            document.body.removeChild(script);
            document.head.removeChild(style);
            window.removeEventListener("message", calendlyEventHandler);
        }
    }, [finish]);

    return (
        <Button type='button' className="mx-auto" onClick={() => {
            window.Calendly.initPopupWidget({
                url: 'https://calendly.com/askly_me/30min',
                prefill: {
                    name: userDetails.name,
                    email: userDetails.email,
                    customAnswers: {
                        a1: webURL,
                    }
                }
            })
        }}>
            {t("Book a demo")}
        </Button>
    )
}