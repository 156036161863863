import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { changeLanguage, checkPassword, getLanguage, isEmail } from '../../logic/util';
import backgroundSide from '../../resources/askly-small-white.svg';
import { useSignupMutation } from "../../state/api";
import { CustomToggle } from "../base/minor/CustomDropdown";
import { LanguageSwitch } from "../base/minor/LanguageSwitch";
import { AuthWrap } from "./SignIn";

export default function SignUp(props) {
    const [staticEmail, setStaticEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [errors, setErrors] = useState({});
    const [confirmSent, setConfirmSent] = useState(false);
    const { t } = useTranslation("noauth")
    const [signup] = useSignupMutation();

    useEffect(() => {
        if (window.fbq)
            window.fbq('track', 'ViewContent');

        // LinkedIn insight tag
        window._linkedin_partner_id = "1482812";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
        (function (l) {
            if (!l) {
                window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                window.lintrk.q = []
            }
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript"; b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
        })(window.lintrk);
    }, [])

    useEffect(() => {
        let loginHint = new URLSearchParams(props.location.search).get("login_hint")
        if (loginHint) {
            setEmail(loginHint)
            setStaticEmail(true)
        }
    }, [props.location]);

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (email.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { email: t("Email is required") })
            validated = false;
        } else if (!isEmail(email)) {
            tmpErrors = Object.assign(tmpErrors, { email: t("Valid email is required") })
            validated = false;
        }

        if (name.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { name: t("Name is required") })
            validated = false;
        }

        if (password.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { password: t("Password is required") })
            validated = false;
        } else {
            let errors = checkPassword(password) || [];
            if (errors.length > 0) {
                tmpErrors = Object.assign(tmpErrors, { password: t("Password must match requirements"), passwordReq: errors })
                validated = false;
            }
        }

        if (!validated) setErrors(tmpErrors)
        return validated
    }

    function langSwitch(lang) {
        changeLanguage(lang)
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (validateForm()) {
            signup({ email, name, password }).unwrap().then(response => {
                setConfirmSent(true);
            }).catch(err => {
                if (err.statusCode) {
                    if (err.statusCode === "EMAIL_TAKEN") {
                        setErrors({ email: t("Email taken") })
                    } else if (err.statusCode === "INVALID_PASSWORD") {
                        setErrors({ password: t("Password must match requirements") })
                    } else if (err.statusCode === "EMAIL_UNVERIFIED") {
                        setErrors({ email: t("Email unverified") })
                    } else {
                        setErrors({ general: t("Something went wrong", { ns: 'common' }) })
                    }
                } else {
                    setErrors({ general: t("Something went wrong", { ns: 'common' }) })
                }
            });
        }
    }

    const leftContent = (
        <div className="left-body">
            <img className="left-bg" src={backgroundSide} alt="bg-askly" />
            <div className="text-wrap">
                <div className="left-txt-main">{t("GET STARTED IN A FEW CLICKS")}</div>
                <div className="left-txt-sub mt-auto mb-5">
                    <div className="mb-3">
                        <Trans t={t} defaults="New here ? <booklink>Book a demo</booklink>" components={{ booklink: <a className="demo-link" href="https://calendly.com/askly_me/30min" /> }} />
                    </div>
                    <Trans t={t} defaults="Make sure customer service is your #1 sales channel.<br> Askly is the only plug&play chat, that creates positive customer experience. Grow your business with ease." />
                </div>
            </div>
        </div>)

    const rightContent = (<>
        <div style={{ display: 'flex', alignItems: 'center', height: "heigth:200px", margin: "20px -24px 20px 0px", float: "right" }}>
            <LanguageSwitch className='mr-3' />
            <Link to="/sign-in" className="df-btn auth-switch">{t("Sign-in")}</Link>
        </div>
        <div className="form-wrap">
            <div className="auth-form" style={{ margin: "auto" }}>
                <div style={{ maxWidth: "485px" }}>
                    {!confirmSent &&
                        <form onSubmit={handleSubmit} autoComplete="on">
                            <p className="login-details mb-0">{t("Register")}</p>
                            <p className="general-error mb-3">{errors.general}</p>
                            <div className="mx-auto text-left" style={{ width: "75%" }}>
                                <Form.Group className={" search-dropdown-wrap w-100"}>
                                    <Form.Label>{t("Language")}</Form.Label>
                                    <div className="search-dropdown justify-content-center ">
                                        <Dropdown className="w-100" >
                                            <Dropdown.Toggle className="w-100 mw-100" as={CustomToggle} >
                                                {getLanguage((i18next.language || "en"), "iso").nativeName}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="w-100 mw-100">
                                                {i18next.language !== 'en' && <Dropdown.Item onSelect={() => { langSwitch("en") }} >English</Dropdown.Item>}
                                                {i18next.language !== 'et' && <Dropdown.Item onSelect={() => { langSwitch("et") }} >Eesti</Dropdown.Item>}
                                                {i18next.language !== 'pl' && <Dropdown.Item onSelect={() => { langSwitch("pl") }} >Polski</Dropdown.Item>}
                                            </Dropdown.Menu>
                                            <Form.Text className="error-text-sm">{/**/}</Form.Text>
                                        </Dropdown>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("Email address")}</Form.Label>
                                    <Form.Control autoComplete="email" type="text" placeholder={t("Enter email")} value={email} disabled={staticEmail} onChange={e => setEmail(e.target.value)} />
                                    <Form.Text className="error-text-sm">{errors.email}</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("Full name")}</Form.Label>
                                    <Form.Control autoComplete="name" type="text" placeholder={t("Full name")} onChange={e => setName(e.target.value)} maxLength={75} />
                                    <Form.Text className="error-text-sm">{errors.setName}</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("Password")}</Form.Label>
                                    <Form.Control autoComplete="new-password" type="password" placeholder={t("Password")} onChange={e => setPassword(e.target.value)} />
                                    <Form.Text className="error-text-sm">{errors.password}</Form.Text>
                                </Form.Group>
                            </div>
                            <div className="row password-requirements px-2 mt-2">
                                <div className={"col-6" + (errors.passwordReq && errors.passwordReq.includes("err_lower") ? " error" : "")}>{t("One lowercase character")}</div>
                                <div className={"col-6" + (errors.passwordReq && errors.passwordReq.includes("err_upper") ? " error" : "")}>{t("One uppercase character")}</div>
                                <div className={"col-6" + (errors.passwordReq && errors.passwordReq.includes("err_digits") ? " error" : "")}>{t("One number")}</div>
                                <div className={"col-6" + (errors.passwordReq && errors.passwordReq.includes("err_len") ? " error" : "")}>{t("6 characters minimum")}</div>
                            </div>
                            <button className="df-btn" style={{ marginTop: "16px" }}>{t("Create account")}</button>
                            <div className="sidelines"><span >{t("OR")}</span></div>
                            <div className="social-grid">
                                <a href="/api/login/google" className="df-btn df-btn-inverse google">{t("Google")}</a>
                                <a href="/api/login/facebook" className="df-btn df-btn-inverse facebook">{t("Facebook")}</a>
                                <a href="/api/login/apple" className="df-btn df-btn-inverse apple ">{t("Apple")}</a>
                            </div>
                            <p className="tos-agree-text">
                                <Trans t={t} defaults="By clicking Create account, I confirm that I have read and agree to the <terms>Askly Terms of Service</terms>, <privacy>Privacy Policy</privacy>, and to receive emails and updates."
                                    components={{
                                        terms: <a href="https://askly.me/en/terms-of-service" />,
                                        privacy: <a href="https://askly.me/en/privacy-policy" />,
                                    }} />
                            </p>

                        </form>
                    }
                    {confirmSent &&
                        <>
                            <p className="login-details mb-0  mt-5">{t("Email sent")}</p>
                            <p className="signup-confirm pt-5 mb-auto">{t("Please verify your email. You can find the verification link on your email")}</p>
                        </>
                    }

                </div>
            </div>
        </div>
    </>)

    return <AuthWrap
        leftContent={leftContent}
        rightContent={rightContent}
    />
}