import * as Sentry from "@sentry/browser";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useUnsubscribeFacebookMutation, useUpdateSiteDetailsMutation } from "../../../state/api";
import { fetchMyDetails } from '../../../state/currentUser';
import { addErrorToast, addInfoToast } from '../../base/ToastManager';
import GlobalNavigationBlocker from '../../base/minor/GlobalNavigationBlocker';
import GuideButton from '../../base/minor/GuideButton';
import { InfoTooltip } from '../../base/minor/Tooltip';
import useScrollToLocation from "../../base/useScrollToLocation";
import ConfirmModal from '../../modals/ConfirmModal';
import FacebookPagesModal from '../../modals/FacebookPagesModal';
import GoogleAnalyticsManage from "../../modals/GoogleAnalyticsManage";
import { addModal } from '../../modals/ModalManager';
import PipedriveManage from "../../modals/PipedriveManage";
import { QRManage } from "../../modals/QuickReplyManagement";
import SendToDeveloperModal from '../../modals/SendToDeveloperModal';
import { DefaultSettingsWrap } from './Base';



export default function TeamSettings({ canSave }) {
    const dispatch = useDispatch()
    const [updateSiteDetails] = useUpdateSiteDetailsMutation();
    const [apiUnsubscribeFacebook] = useUnsubscribeFacebookMutation();
    const { t } = useTranslation("settings_team")
    const siteDetails = useSelector(state => state.user.siteDetails)
    const userDetails = useSelector(state => state.user.userDetails)
    let isAdminRights = ["OWNER", "ADMIN"].includes(userDetails.currentSiteRole)
    const [siteDetailsCopy, setSiteDetailsCopy] = useState({})
    const prevDetails = useRef({});
    const [errorMsgs, setErroMsgs] = useState({})

    useScrollToLocation()
    useEffect(() => {
        if (siteDetails !== prevDetails.current) {
            // Clone so we can compare
            let siteDetailsClone = JSON.parse(JSON.stringify(siteDetails || {}));
            setSiteDetailsCopy(siteDetailsClone);
        }

        prevDetails.current = siteDetails;
    }, [siteDetails]);

    function unsubscribeFacebookPopup(pageID) {
        addModal(<ConfirmModal />, {
            data: {
                title: t("Unlink facebook page ?"),
                body: t("Are you sure you want to unlink facebook page ?"),
                includeLoading: true,
            },
            onConfirm: () => {
                return apiUnsubscribeFacebook(pageID).then(r => {
                    addInfoToast(t("Facebook page unlinked"))
                    dispatch({ type: "SITE:FB_UNSUBSCRIBE", payload: pageID })
                }).catch(e => {
                    addErrorToast(t("Could not unsubscribe Facebook page"))
                })
            }
        })

    }

    function openQRManager() {
        addModal(<QRManage />, {
            data: {}
        })
    }

    function sendToDeveloperPopup() {
        addModal(<SendToDeveloperModal />, {
            data: {},
            onConfirm: () => { }
        })
    }

    function saveSiteSettings(e) {
        e.preventDefault()
        let isValidated = true
        let errors = {};

        if (!siteDetailsCopy.name) {
            isValidated = false
            errors.name = t("Invalid name")
        } else if (siteDetailsCopy.name?.length < 3) {
            isValidated = false
            errors.name = t("Name needs to be atlest 3 letters")
        }

        if (isValidated) {
            updateSiteDetails(siteDetailsCopy).unwrap().then(() => {
                addInfoToast(t("Settings saved"))
                dispatch(fetchMyDetails())
            }).catch(e => {
                addErrorToast(t("Could not save settings"))
            }).finally(() => {
                setErroMsgs({})
            })
        } else {
            setErroMsgs(errors)
            addErrorToast(t("Could not save"))
        }
    }

    const checkIfHasChanges = () => {
        return canSave &&
            (JSON.stringify(siteDetailsCopy || {}) !== JSON.stringify(siteDetails))
    }

    function integrationPDPopup() {
        addModal(<PipedriveManage />, {
            data: { siteDetails },
        })
    }

    function integrationGA4Popup() {
        addModal(<GoogleAnalyticsManage />, {
            data: { siteDetails },
        })
    }

    const showPagesModal = () => {
        window.FB.login(function (loginResponse) {
            if (loginResponse.authResponse?.accessToken && loginResponse.status === 'connected') {
                window.FB.api('/me/accounts?fields=picture,id,name,username,connected_instagram_account{name,biography,profile_picture_url}', function (response) {
                    addModal(<FacebookPagesModal />, {
                        pages: response.data,
                        userAccessToken: loginResponse.authResponse.accessToken
                    })
                });
            } else {
                Sentry.captureException(loginResponse);
                addErrorToast(t("Could not retrive FB pages"))
            }
        }, { scope: 'business_management, pages_manage_metadata, pages_messaging, instagram_basic, instagram_manage_messages' });
    }

    let siteScript = '<script src="https://chat.askly.me/cw/chat/latest.js"\n\t tw-client-key="' + siteDetails.siteKey + '" async ></script>'
    let facebookPages = siteDetails.facebookChannel?.map(p => <Button key={p.pageID} className="d-block facebook mb-2" onClick={() => unsubscribeFacebookPopup(p.pageID).unwrap()}>{t("Unlink {{pageName}} Facebook page", { pageName: p.pageName })}</Button>)

    return (
        <DefaultSettingsWrap subPageTitle={t("General", { ns: 'settings_nav' })} canSave={canSave}>
            <div className="chat-settings-wrap">
                <GlobalNavigationBlocker check={checkIfHasChanges} message={t("Changes that you made may not be saved.")} />
                <Form id="current-settings-form" onSubmit={saveSiteSettings} >
                    <Form.Group>
                        <Form.Label>{t("Organization Name")}<span className="general-error ml-2">{errorMsgs.name}</span></Form.Label>
                        <Form.Control type="text" placeholder="ABC Corporation" value={siteDetailsCopy.name || ""} maxLength={55} onChange={e => { setSiteDetailsCopy({ ...siteDetailsCopy, name: e.target.value }) }} disabled={!canSave} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("Client key")}</Form.Label>
                        <Form.Control type="text" value={siteDetails.siteKey || ""} disabled />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {t("Chat script")}
                            <InfoTooltip>
                                <Trans t={t}
                                    shouldUnescape={false}
                                    defaults="Copy/paste the chat script to your webpages head tag.<br /><tourtip>Ask your developer to help you. Or find guidelines on how to add Askly chat to your website by clicking on the images in “How to setup”</tourtip>"
                                    components={{ tourtip: <i className="tour-tip"></i> }} />
                            </InfoTooltip>
                        </Form.Label>
                        <Form.Control rows="2" as="textarea" type="text" placeholder="" value={siteScript} disabled />
                        <Button onClick={sendToDeveloperPopup} className="mt-3" variant="outline-primary"><i className="fas fa-envelope mr-2" />{t("Send to developer")}</Button>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {t("How to setup")}
                            <InfoTooltip>
                                {t("Find guidelines on how to add Askly chat to your website.")}
                            </InfoTooltip>
                        </Form.Label>
                        <div className="d-flex flex-wrap">
                            <GuideButton type="shoproller" />
                            <GuideButton type="shopify" />
                            <GuideButton type="wordpress" />
                            <GuideButton type="woocommerce" />
                            <GuideButton type="wix" />
                            <GuideButton type="magento" />
                            <GuideButton type="voog" />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {t("Quick replies")}
                            <InfoTooltip>
                                {t("Save common replies as quick replies")}
                            </InfoTooltip>
                        </Form.Label>
                        <div>
                            <Button onClick={openQRManager} className="" variant="outline-primary"><i className="fas fa-bookmark mr-2" />{t("Manage quick replies")}</Button>
                        </div>
                    </Form.Group>
                    {
                        isAdminRights &&
                        <><Form.Group>
                            <Form.Label>
                                {t("Reply to all messages from Askly:")}
                                <InfoTooltip>
                                    {t("Connect your Facebook Messenger and Instagram business account and have all chats in one place.")}
                                </InfoTooltip>
                            </Form.Label>
                            {facebookPages}
                            <Button className="d-block facebook" onClick={showPagesModal}>{t("Add Facebook and Instagram business pages")}</Button>
                            <div id="fb-connect"></div>
                        </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {t("Pipedrive integration")}
                                </Form.Label>
                                <div>
                                    <Button onClick={integrationPDPopup} className="" variant="outline-primary">{t("Manage")}</Button>
                                </div>
                            </Form.Group>
                            {<Form.Group>
                                <Form.Label>
                                    {t("Google analytics integration")}
                                </Form.Label>
                                <div>
                                    <Button onClick={integrationGA4Popup} className="" variant="outline-primary">{t("Manage")}</Button>
                                </div>
                            </Form.Group>}
                        </>
                    }
                </Form >
            </div>
        </DefaultSettingsWrap>)

}
