import cn from 'classnames';
import React, { useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import './ChipsInput.scss';

export default function ChipsInput({ label, error, description, className, disableInserting, renderBadge, badgeSelection = [], allBadges = [], inputOtherParams, inputOtherKey, allowOther, addBadge, removeBadge, insertBadge, hideX }) {
    const { t } = useTranslation("common")

    let allowedBadgesElements = allBadges.map((val, i) => {
        let active = badgeSelection?.includes(val)
        return <Chip key={i} value={val} isActive={active} hideX={true} renderBadge={renderBadge} onClick={() => { active ? removeBadge?.(val) : addBadge?.(val) }} />
    });

    return (
        <div className={cn("add-tw-tag-wrap", className)} >
            <Form.Label className='mb-0'>{label}
                <span className={cn("ml-2", { "general-error": error, "general-success": !error })}>{t("*Required")}</span>
            </Form.Label>
            <div className='chips-description'>{description}</div>
            <div className='d-flex flex-wrap mt-1'>
                {allowedBadgesElements}
            </div>
            {allowOther && <div className="add-tw-chip">
                <input
                    key={inputOtherKey}
                    maxLength={120}
                    className='mt-2'
                    placeholder={t("Other")}
                    {...inputOtherParams}
                />
            </div>}
        </div>
    )
}


function Chip({ value, isActive, renderBadge, onClick, hideX }) {
    const { t } = useTranslation("common")

    return (
        <div className={cn("askly-chip ", { "hasX": !hideX, 'active': isActive })} onClick={onClick ? onClick : null} >
            <span> {renderBadge ? (renderBadge(value)) : (value ? value : t("Unknown"))}{!hideX && <i className="ml-1 fal fa-times pointer remove" onClick={null}></i>}</span>
        </div >
    )
}

export const CustomInputToggle = React.forwardRef(({ className, children, onClick, onInputKeyDown, value, setValue }, ref) => {
    const { t } = useTranslation("common")

    return (
        <div className="add-tw-chip mt-2">
            <input ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}
                onKeyDown={onInputKeyDown}
                placeholder={t("Other")}
                value={value}
                onChange={e => { setValue(e.target.value) }}
                maxLength="120" ></input >
        </div>
    )
});



const Custtommenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy, close, externalFilter, externalFilterValue, noMatchesText, placeholder }, ref) => {
        const [value, setValue] = useState('');
        const { t } = useTranslation("common")

        if (isMobile) {
            style = {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: "0px",
                zIndex: 3000,
            }
        }

        let filteredChildren = externalFilter ?
            React.Children.toArray(children).reverse().filter((child) => !externalFilterValue || (child.props.value?.toLowerCase().includes(externalFilterValue.toLowerCase()))) :
            React.Children.toArray(children).reverse().filter((child) => !value || (child.props.value?.toLowerCase().includes(value.toLowerCase())))

        return (
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy} >
                <div className="d-flex mx-3">
                    {!externalFilter && <FormControl
                        autoFocus
                        placeholder={placeholder || t("Type to filter...")}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                event.preventDefault();
                                return false;
                            }
                        }}
                        value={value}
                    />}
                    {isMobile && <div className="ml-3 cancel-dd" onClick={close}>
                        {t("Cancel")}
                    </div>}
                </div>

                <div className="dropdown-main-menu py-3 px-2">
                    {filteredChildren.length > 0 && filteredChildren}
                    {filteredChildren.length === 0 && <div className="text-center mt-2 text-muted">{noMatchesText ? noMatchesText : t("No matches")}</div>}
                </div>
            </div>
        );
    },
);
