import LockedModal from '../components/modals/LockedModal';
import { addModal } from '../components/modals/ModalManager';
import pageTitleNotification from '../logic/tabNotification';
import { deleteCookie } from '../logic/util';
import { assignChatSuccess, fetchChatData, messsagesUpdate, messsageUpdate, newExternalMessage, otherSiteMessage, refreshChatData } from './activeChat';
import { fetchChatList } from './chatList';
import { changeActiveSite } from './currentUser';

export const wsEvent = (event) => (dispatch, getState) => {
    let { siteDetails } = getState().user;

    if (event.siteID && event.siteID !== siteDetails.id) {
        switch (event.eventType) {
            case "CHAT:NEW_MESSAGE":
                if (!event.message.isSupportResponse && event.message.status !== "askly_assistant") {
                    let message = (event.message.attachments && event.message.attachments.length ? event.message.attachments[0].translationText : event.message.messageContent)
                    pageTitleNotification.NewMsgNotification(message, () => {
                        // TODO CHANGE ACTIVE SITE ETC
                        dispatch(changeActiveSite(event.siteID, event.message?.contactID))
                    })
                }
                dispatch(otherSiteMessage(event.siteID, event.message?.contactID));
                break;
            case "REMOVED_FROM_TEAM":
                break;
            default:
                break;
        }
    } else {
        switch (event.eventType) {
            case "CHAT:NEW_MESSAGE":
                dispatch(newExternalMessage(event.message));
                break;
            case "CHAT:MULTI_MESSAGE_UPDATE":
                dispatch(messsagesUpdate(event.messages, event.contactID));
                break;
            case "CHAT:MESSAGE_UPDATE":
                dispatch(messsageUpdate(event.message));
                break;
            case "CHAT:ASSIGN": // TODO Better
                dispatch(assignChatSuccess(event.assignment.contactID, event.assignment.currentAssigneeID))
                dispatch(fetchChatList());
                dispatch(refreshChatData(event.assignment.contactID));
                break;
            case "CHAT:ADD_TAG":
            case "CHAT:REMOVE_TAG":
                dispatch({ type: event.eventType, payload: event.tagEvent })
                break;
            case "VISITOR:PAGE_VISIT":
                dispatch({ type: event.eventType, payload: event.pageVisitEvent })
                break;
            case "VISITOR:NAME":
                dispatch({ type: event.eventType, payload: event.visitorEvent })
                break;
            case "VISITOR:EMAIL":
                dispatch({ type: event.eventType, payload: event.visitorEvent })
                break;
            case "VISITOR:PHONE_NR":
                dispatch({ type: event.eventType, payload: event.visitorEvent })
                break;
            case "VISITOR:NOTES":
                dispatch({ type: event.eventType, payload: event.notes })
                break;
            case "SITE:BREAK":
                dispatch({ type: event.eventType, payload: event.breakEvent })
                break;
            case "CHAT:DELETED":
            // fallsthrough
            case "CHAT:BLOCK":
            // fallsthrough
            case "CHAT:ARCHIVE":
                dispatch({ type: event.eventType, payload: { contactID: event.contactID } })
                dispatch(fetchChatList());
                break;
            case "CHAT:RE_OPEN":
                dispatch(fetchChatList());
                break;
            case "CHAT:UNASSIGN_FROM_ASSISTANT":
                dispatch(fetchChatList());
                pageTitleNotification.AssistantUnassign(() => { dispatch(fetchChatData(event.contactID)) })
                break;
            case "CHAT:NEW": // TODO Better
                pageTitleNotification.NewUser()
                dispatch(fetchChatList());
                break;
            case "CHAT:VISITOR_SET_SEEN_AT":
                if (event.contactID) {
                    dispatch({ type: event.eventType, payload: { seenAt: event.seenAt, contactID: event.contactID } })
                }
                break;
            case "REMOVED_FROM_TEAM":
                deleteCookie("tw_ASI")
                deleteCookie("tw_token")
                addModal(<LockedModal />, {
                    locked: true,
                    data: {
                        title: "Access lost",
                        body: "Please reload the page",
                        modalIcon: <i className="fas fa-lock"></i>,
                    },
                })

                break;
            default:
                if (event.payload)
                    dispatch({ type: event.eventType, payload: event.payload })
                break;
        }
    }
} 