import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, getLanguage, getLanguages, getLanguagesIsoToEng, toFormatedDateString } from '../../../logic/util';
import { useRemoveDeviceMutation, useSaveMyPrefLangMutation } from '../../../state/api';
import { fetchMyDetails, updateMySettings } from '../../../state/currentUser';
import { addErrorToast, addInfoToast } from '../../base/ToastManager';
import BadgeDropdown from '../../base/minor/BadgeDropdown';
import { CustomToggle } from '../../base/minor/CustomDropdown';
import GlobalNavigationBlocker from '../../base/minor/GlobalNavigationBlocker';
import { InfoTooltip } from '../../base/minor/Tooltip';
import VolumeSlider from '../../base/minor/VolumeSlider';
import ConfirmModal from '../../modals/ConfirmModal';
import { addModal } from '../../modals/ModalManager';
import { DefaultSettingsWrap } from "./Base";
import './Personal.scss';

export default function Personal() {
    const dispatch = useDispatch()
    const { t } = useTranslation("settings_personal")
    const userDetails = useSelector(state => state.user.userDetails)
    const [userSettings, setUserSettings] = useState({})
    const prevDetails = useRef({});
    const [removeDevice] = useRemoveDeviceMutation();
    const [saveMyPrefLang] = useSaveMyPrefLangMutation();

    useEffect(() => {
        if (userDetails !== prevDetails.current) {
            let dcDetails = JSON.parse(JSON.stringify(userDetails || {}));
            setUserSettings(dcDetails);
        }

        prevDetails.current = userDetails;
    }, [userDetails]);

    function changeName(name) {
        setUserSettings({ ...userSettings, name })
    }

    function addLanguage(newLang) {
        let langFull = getLanguage(newLang, "eng")
        let currentLangs = userSettings.languages || [];
        setUserSettings({ ...userSettings, languages: [...currentLangs.filter(lang => lang !== langFull.iso), langFull.iso] })
    }

    function removeLanguage(removedLanguage) {
        let langFull = getLanguage(removedLanguage, "eng")
        let currentLangs = userSettings.languages || []
        setUserSettings({ ...userSettings, languages: [...currentLangs.filter(lang => lang !== langFull.iso)] })
    }

    function saveUserSettings(e) {
        e.preventDefault()
        dispatch(updateMySettings({
            languages: (userSettings.languages || []),
            ignoreEmailMsgNotifications: userSettings.ignoreEmailMsgNotifications,
            name: userSettings.name,
            notificationsPref: userSettings.notificationsPref,
        }))
    }

    const checkIfHasChanges = () => {
        return JSON.stringify(userSettings) !== JSON.stringify(userDetails)
    }

    const notificationsPrefSetter = (name) => {
        return (newValue) => {
            setUserSettings({ ...userSettings, notificationsPref: { ...userSettings.notificationsPref, [name]: newValue } })
        }
    }

    const notificationsPrefGetter = (name) => {
        return userSettings?.notificationsPref?.[name]
    }

    const languageSwap = (lng) => {
        saveMyPrefLang(lng).unwrap().then(() => {
            changeLanguage(lng)
        })
    }

    return (
        <DefaultSettingsWrap subPageTitle={t("Account", { ns: 'settings_nav' })} canSave>
            <Form id="current-settings-form" onSubmit={saveUserSettings}>
                <GlobalNavigationBlocker check={checkIfHasChanges} message={t("Changes that you made may not be saved.")} />
                <Form.Group>
                    <Form.Label>{t("Full name")}</Form.Label>
                    <Form.Control type="text" placeholder={t("Enter name")} value={userSettings.name || ""} onChange={e => { changeName(e.target.value) }} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Email address")}</Form.Label>
                    <Form.Control type="email" placeholder={t("Enter email")} value={userDetails.email || ""} disabled />
                </Form.Group>
                <Form.Group className={" search-dropdown-wrap"}>
                    <Form.Label>{t("Display language")}</Form.Label>
                    <div className="search-dropdown">
                        <Dropdown >
                            <Dropdown.Toggle as={CustomToggle} >
                                {getLanguage((i18next.language || "en"), "iso").nativeName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                {i18next.language !== 'en' && <Dropdown.Item onSelect={() => { languageSwap('en') }} >English</Dropdown.Item>}
                                {i18next.language !== 'et' && <Dropdown.Item onSelect={() => { languageSwap('et') }} >Eesti</Dropdown.Item>}
                                {i18next.language !== 'pl' && <Dropdown.Item onSelect={() => { languageSwap('pl') }} >Polski</Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Form.Group>
                <BadgeDropdown
                    infoPopover={
                        <InfoTooltip>
                            <Trans t={t}
                                defaults="Choose the languages YOU can speak in.<br/><tourtip>You can have only one, or as many as you feel comfortable writing in</tourtip>"
                                shouldUnescape={true}
                                components={{ br: <br></br>, tourtip: <i className="tour-tip"></i> }} />
                        </InfoTooltip>}
                    label={t("Choose the languages you can speak")}
                    addBadge={addLanguage}
                    removeBadge={removeLanguage}
                    badgeSelection={getLanguages()}
                    badges={userSettings.languages ? getLanguagesIsoToEng(userSettings.languages) : []}
                />
                <Form.Group>
                    <Form.Label>{t("Notifications")}
                        <InfoTooltip>
                            <Trans t={t} defaults="Choose if you receive email notification of new chat messages.<br/><tourtip>Useful so you don’t miss any new messages</tourtip>" shouldUnescape={true}
                                components={{ tourtip: <i className="tour-tip"></i> }} />
                        </InfoTooltip>
                    </Form.Label>
                    <Form.Check type="switch" id="ignore-msg-email" label={t("Get email when customer starts a chat")} checked={!userSettings.ignoreEmailMsgNotifications} onChange={e => { setUserSettings({ ...userSettings, ignoreEmailMsgNotifications: !userSettings.ignoreEmailMsgNotifications }) }} />
                    <SoundNotification
                        text={t("New chat sound notification")}
                        setVolumeSlider={notificationsPrefSetter("ncVol")}
                        volumeValue={notificationsPrefGetter("ncVol")}
                        setTimes={notificationsPrefSetter("ncX")}
                        timesValue={notificationsPrefGetter("ncX")}
                        setSound={notificationsPrefSetter("ncS")}
                        soundValue={notificationsPrefGetter("ncS") || "none"}
                    />
                    <SoundNotification
                        text={t("New message sound notification")}
                        setVolumeSlider={notificationsPrefSetter("nmVol")}
                        volumeValue={notificationsPrefGetter("nmVol")}
                        setTimes={notificationsPrefSetter("nmX")}
                        timesValue={notificationsPrefGetter("nmX")}
                        setSound={notificationsPrefSetter("nmS")}
                        soundValue={notificationsPrefGetter("nmS") || "sound_1"}
                    />
                </Form.Group>
                {userDetails.mobileAuths &&
                    <Form.Group>
                        <Form.Label>{t("Authenticated devices")}</Form.Label>
                        {userDetails.mobileAuths?.map(m => (
                            <div key={m.id} className="d-flex device-details">
                                <div className="d-flex mx-2">
                                    <i className="far fa-mobile-alt  my-auto fa-lg"></i>
                                </div>
                                <div>
                                    <div className="device-name">{m.deviceName || "Unknown"}</div>
                                    <div className="device-info">{m.deviceType} &#8226;  {toFormatedDateString(m.updatedAt, true)}</div>
                                </div>

                                <Button variant="outline-secondary" size="sm" className="ml-3 my-auto" onClick={() => {
                                    addModal(<ConfirmModal />, {
                                        data: {
                                            title: t("Remove device ?"),
                                            body: t("Are you sure you want to remove authenticated device ?"),
                                            includeLoading: true,
                                        },
                                        onConfirm: () => {
                                            return removeDevice(m.id).unwrap().then(() => {
                                                addInfoToast(t("Device removed"))
                                                dispatch(fetchMyDetails())
                                            }).catch(() => {
                                                addErrorToast(t("Error removing device"))
                                            })
                                        },
                                    })
                                }}>{t("Remove", { ns: 'common' })}</Button>

                            </div>
                        ))}
                    </Form.Group>}
            </Form>
        </DefaultSettingsWrap>
    )
}



function SoundNotification({ text, setVolumeSlider, volumeValue, setTimes, timesValue, setSound, soundValue }) {
    const { t } = useTranslation("settings_personal")

    const soundsNames = {
        'none': t("None"),
        'sound_1': "Bling 1",
        'sound_4': "Bling 2",
        'sound_3': "Bells 1",
        'sound_5': "Bells 2",
        'sound_6': "Bells 3",
        'sound_2': "Transition",
    }

    const repeatNames = {
        '1': t("{{count}} time", { count: 1 }),
        '5': t("{{count}} time", { count: 5 }),
        '10': t("{{count}} time", { count: 10 }),
        'forever': t("Until opened"),
    }

    function playSound(name) {
        if (name && name !== 'none') {
            let audio = new Audio("/notifications/" + name + ".mp3")
            audio.volume = volumeValue || 0.6;
            audio.play().catch(e => { console.error(e) });
        }
    }

    return (
        <div style={{ display: 'flex', color: "#5d6c89", alignItems: 'center', marginTop: 12 }}>
            {text}
            <DropdownButton onSelect={(eventKey) => { playSound(eventKey); setSound(eventKey) }} size='sm' className='ml-2' variant="outline-secondary" title={soundsNames[soundValue || "sound_1"]} menuAlign="right">
                {Object.entries(soundsNames).map(([key, value], i) => <Dropdown.Item key={key} eventKey={key} >{value}</Dropdown.Item>)}
            </DropdownButton>
            {(soundValue !== 'none') && < DropdownButton onSelect={(eventKey) => { setTimes(eventKey) }} size='sm' className='ml-2' variant="outline-secondary" title={repeatNames[timesValue || "1"]} menuAlign="right">
                {Object.entries(repeatNames).map(([key, value], i) => <Dropdown.Item key={key} eventKey={key} >{value}</Dropdown.Item>)}
            </DropdownButton>}
            {(soundValue !== 'none') && <VolumeSlider value={volumeValue} setValue={setVolumeSlider} onMouseUp={() => { playSound(soundValue || "sound_1") }} />}
        </div >
    )
}